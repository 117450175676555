import { Box } from "@mui/material";
import { AxiosError } from "axios";
import Button from "components/common/Button";
import { Input } from "components/common/FormComponents";
import errorMessage from "constants/errorMessage";
import { route } from "constants/routes";
import useTimer from "hooks/useTimer";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { setNewPassToken } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { sendOtp, verifyCode } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import styles from "./styles";

type AuthenticationFormData = {
  otp: string;
};

const Authentication = () => {
  const { newPassToken, signUpEmail } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [{ min, sec }, reset, isTimedOut] = useTimer(30);

  const { control, formState, handleSubmit, setError } =
    useForm<AuthenticationFormData>({
      defaultValues: {
        otp: "",
      },
      criteriaMode: "all",
      mode: "onChange",
    });

  const { mutate: otpSend } = useMutation(
    "sendOtp",
    (data: { email: string }) => sendOtp(data),
    {
      onSuccess: (data) => {
        dispatch(setNewPassToken(data.data.data.token));
      },
    },
  );

  const { mutate: otpVerify } = useMutation(
    "verifyOtp",
    (data: { otp: string; token: string }) => verifyCode(data),
    {
      onSuccess: (data) => {
        history.replace(route.staff.path);
      },
      onError: (err: AxiosError) => {
        setError("otp", {
          message: err?.response?.data?.message || "Please enter a valid code",
        });
      },
    },
  );

  const history = useHistory();
  const onSubmit = (data: AuthenticationFormData) => {
    otpVerify(
      getTrimmedData({
        ...data,
        token: newPassToken,
      }),
    );
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.authentication}>Authentication</Box>
      <Box sx={styles.heading}>
        Please enter the authentication code sent to your email.
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="otp"
          label="Code"
          control={control}
          errors={formState.errors}
          customStyles={styles.input}
          rules={{
            required: { value: true, message: errorMessage.validCode },
          }}
        />

        <Button
          type="submit"
          label="Continue"
          customStyles={styles.continueBtn}
          disabled={!formState.isValid}
        />
      </Box>
      <Box
        onClick={() => {
          if (isTimedOut) {
            otpSend(getTrimmedData({ email: signUpEmail }));
            reset();
          }
        }}
        sx={{
          ...styles.signInLink,
          color: !isTimedOut ? "gray" : "primary.main",
          cursor: !isTimedOut ? "not-allowed" : "pointer",
        }}
      >
        Resend
      </Box>
      <Box sx={styles.timer}>
        Resend in: {min < 10 ? `0${min}` : min}:{sec < 10 ? `0${sec}` : sec}s
      </Box>
    </Box>
  );
};

export default Authentication;
