import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    "& a": {
      textDecoration: "underline",
    },
    "& td": {
      textAlign: "center",
      padding: "11px",
      border: "none",
      borderRadius: "5px",
    },
    "& .MuiInputBase-root": {
      height: "28px",
    },
    display: "flex",
    height: "calc(100vh - 90px)",
    flexBasis: "100%",
    flexDirection: "column",
  },

  familyMemberTableWrapper: {
    height: "calc(100vh - 270px)",
  },

  tableLink: {
    color: "primary.main",
    cursor: "pointer",
    textDecoration: "underline",
    overflow: "hidden",
  },

  tableLinkDisabled: {
    color: "custom.background.darkGray",
    cursor: "not-allowed",
    fontWeight: 300,
    textDecoration: "underline",
  },

  status: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
  },

  del: {
    cursor: "pointer",
  },

  table: {
    borderBottom: "none",
  },

  searchBar: {
    width: "50%",
    marginBottom: "15px",
  },

  viewAllWrapper: {
    height: "216px",
    overflowY: "auto",
    background: "white",
    color: "custom.text.secondary",
    paddingLeft: "8px",
  },

  viewItemHead: {
    flexBasis: "40%",
    fontWeight: "bold",
    fontSize: "15px",
  },

  viewAll: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    lineHeight: "48px",
    fontSize: "12px",
    fontWeight: "500",
  },

  viewItem: {
    flexBasis: "40%",
  },

  btn: {
    fontSize: "14px",
    minWidth: "150px",
    margin: "10px 0",
    height: "28px",
    "&:disabled": {
      backgroundColor: "primary.light",
      color: "white",
    },
  },
  tableWrapper: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
  },

  formWrapper: {
    position: "relative",
  },

  backStyles: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "15px",
    color: "custom.background.dropdownCta",
    top: "10px",
    left: "10px",
    "&:after": {
      top: "20px",
      content: '""',
      display: "block",
      margin: "auto",
      height: "1.5px",
      width: "50px",
      background: "#056EE6",
      position: "absolute",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "12px",
      marginRight: "0",
    },
  },

  backArrow: {
    fontSize: "14px",
    cursor: "pointer",
  },

  active: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "custom.misc.green",
    cursor: "default",
  },

  inActive: {
    color: "#c0291d",
    fontWeight: 500,
    cursor: "default",
    fontSize: "14px",
    lineHeight: "16px",
  },

  nameWrapper: {
    whiteSpace: "nowrap",
    maxWidth: "180px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },

  isNewDot: {
    width: "3px",
    height: "3px",
    borderRadius: "100px",
    backgroundColor: "#2F6CDE",
  },

  newWrapper: {
    fontSize: "9px",
    color: "#2F6CDE",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    gap: "1px",
    position: "absolute",
    top: "2px",
    right: "10px",
  },

  primaryContactWrapper: {
    display: "flex",
    gap: "8px",
    overflow: "hidden",
  },

  imgStyles: {
    width: "21px",
    height: "19px",
  },
};

export default styles;
