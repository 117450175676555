import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "248px",
    "& .MuiInputBase-root": {
      width: "100% ",
    },
  },

  forgotPassword: {
    fontSize: {
      xl: "30px",
      lg: "30px",
      md: "30px",
      sm: "30px",
      xs: "25px",
    },
    fontWeight: "bold",
    textAlign: {
      xl: "left",
      lg: "left",
      md: "left",
      sm: "left",
      xs: "center",
    },
    whiteSpace: "nowrap",
  },

  heading: {
    marginTop: "16px",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: {
      xl: "left",
      lg: "left",
      md: "left",
      sm: "left",
      xs: "center",
    },
  },

  input: {
    marginTop: "42px",
    "& .label": {
      position: "absolute",
    },
  },

  continueBtn: {
    marginTop: "96px",
    marginBottom: "42px",
    backgroundColor: "primary.dark",
  },
  btnWrapper: {
    display: "flex",
    alignSelf: "center",
    margin: "-15px",
  },

  btn: {
    minWidth: "150px",
    margin: "10px 0",
    height: "28px",
    background: "transparent",
    color: "primary.main",
    boxShadow: "none",
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      textDecoration: "underline",
    },
  },
};

export default styles;
