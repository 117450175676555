import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/system";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import Button from "components/common/Button";
import DataGrid from "components/common/DataGrid";
import Info from "components/common/Info";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import AnnouncementForm from "components/feature/Announcements/Form";
import { errorModal } from "constants/dummyData";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  setAnnouncementData,
  setAnnouncementRowData,
} from "redux/slices/announcement";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  deleteAnnouncementId,
  getAllAnnouncement,
} from "utils/api/announcement";
import {
  announcementDataTransformer,
  announcementRowDataTransformer,
} from "utils/common";
import { InfoModal } from "../ManageResidents";
import styles from "./styles";

type FormType = "add" | "edit";

type AnnouncementFormData = {
  title: string;
  description: string;
  tags: string[];
};

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type DeleteAnnouncement = {
  props: {
    description: string;
  };
} & ModalData;

const AnnouncementPage = () => {
  const [form, setForm] = useState<FormType>();
  const [formData, setFormData] = useState<AnnouncementFormData>();
  const [pageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [openModal, setModal] = useState<boolean>(false);
  // const [viewOnly, setViewOnly] = useState<boolean>(form === "edit");
  const [totalData, setTotalData] = useState(0);

  const {
    user: {
      globalInfoData,
      mrModalView,
      userDetails: { careHomes },
    },
    announcement: { announcementRowData, announcementData },
  } = useAppSelector((state) => state);

  const pccId = careHomes?.[0]?.careHomeId;

  const dispatch = useAppDispatch();

  const { refetch: refetchAllAnnouncements } = useQuery(
    ["getAllAnnouncements", page, pccId, announcementData],
    () => getAllAnnouncement({ pccId, page }),
    {
      onSuccess: (data) => {
        setTotalData(data?.data?.metadata?.total);
        dispatch(
          setAnnouncementData(announcementDataTransformer(data?.data?.data)),
        );
      },
    },
  );

  const { mutate: announcementDelete, isLoading } = useMutation(
    "deleteAnnouncement",
    () => deleteAnnouncementId({ announcementId: announcementRowData?.id }),
    {
      onSuccess: () => {
        refetchAllAnnouncements();
        setModal(false);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setModal(true);
        }
      },
      retry: 1,
    },
  );

  const deleteAnnouncement: DeleteAnnouncement = {
    heading: "Delete Announcement",
    props: {
      description: "Are you sure you want to delete this announcement?",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Yes",
    },
  };

  const handleRightBtnClick = () => {
    announcementDelete();
  };

  // useEffect(() => {
  //   form === "edit" ? setViewOnly(false) : setViewOnly(true);
  // }, [form]);

  useEffect(() => {
    refetchAllAnnouncements();
  }, []);

  const [modalData, setModalData] = useState(deleteAnnouncement);

  const handleClose = () => {
    setModal(false);
    setModalData(deleteAnnouncement);
  };

  const handleAnnouncement = (data: AnnouncementFormData) => data;

  const onClickAdd = () => {
    setForm("add");
    setFormData({
      title: "",
      description: "",
      tags: [],
    });
  };

  const onClickEdit = (data) => {
    setForm("edit");
    setFormData(
      announcementRowDataTransformer(data) as {
        title: string;
        description: string;
        tags: string[];
      },
    );
  };

  const handleDel = () => {
    setModal(true);
    setModalData(deleteAnnouncement);
  };

  const renderTitle = (params: GridCellParams) => {
    return (
      <Box
        sx={styles.tableLink}
        onClick={() => {
          dispatch(
            setAnnouncementRowData(announcementRowDataTransformer(params.row)),
          );
          onClickEdit(params.row);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            dispatch(
              setAnnouncementRowData(
                announcementRowDataTransformer(params.row),
              ),
            );
            onClickEdit(params.row);
          }
        }}
        tabIndex={0}
      >
        {params.row.announcements}
      </Box>
    );
  };

  const renderAction = (params: GridCellParams) => {
    return (
      <Box
        sx={styles.del}
        component="img"
        src="/icons/del-bin.svg"
        alt="del-icon"
        onClick={() => {
          handleDel();
          dispatch(setAnnouncementRowData(params.row));
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleDel();
            dispatch(setAnnouncementRowData(params.row));
          }
        }}
        tabIndex={0}
      />
    );
  };

  const renderTags = (params: GridCellParams) => {
    return <Box>{params.row.tags.map((tag) => `#${tag}`).join(", ")}</Box>;
  };

  const announceColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      headerName: "Announcements",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderTitle,
    },
    {
      field: "tags",
      headerName: "Tags",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderTags,
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "time",
      headerName: "Time",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderAction,
    },
  ];

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleRightBtnClick}
          loading={isLoading}
        >
          <SimplePopUp description={modalData.props.description} />
        </PopUp>
      )}

      <Info
        content={globalInfoData?.content}
        heading={globalInfoData?.heading}
        state={true}
        mrViewAllModal={mrModalView as InfoModal}
        updateState={() => {}}
        customStyles={{
          active: { cursor: "not-allowed" },
          inActive: { cursor: "not-allowed" },
        }}
        disabled
      />

      {!form ? (
        <Box sx={styles.tableWrapper}>
          <Box sx={styles.btnWrapper}>
            <Button
              label="Add Announcement"
              icon
              sx={styles.btn}
              onClick={() => onClickAdd()}
            >
              <AddIcon fontSize="small" sx={styles.icon} />
            </Button>
          </Box>
          <DataGrid
            rows={announcementData || []}
            columns={announceColumn}
            disableSelectionOnClick
            onPageChange={(pageNo) => setPage(pageNo + 1)}
            rowsPerPageOptions={[5]}
            pageSize={pageSize}
            rowCount={totalData}
            loading={false}
            componentsProps={{
              toolbar: { showQuickFilter: true },
            }}
            emptyTable="No announcements added."
          />
        </Box>
      ) : (
        <Box sx={styles.formBtnWrapper}>
          <Box sx={styles.backWrapper}>
            <Box
              sx={styles.backStyles}
              onClick={() => setForm(null)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setForm(null);
                }
              }}
              tabIndex={0}
            >
              <ArrowBackIosIcon sx={styles.backArrow} />
              <Box>Back</Box>
            </Box>
          </Box>
          <AnnouncementForm
            btnLabel={form === "add" ? "" : "Edit Announcement"}
            submitBtnLabel={
              form === "add" ? "Create Announcement" : "Save Announcement"
            }
            viewOnly={form === "edit"}
            data={formData}
            onSubmitForm={handleAnnouncement}
            refetchAllAnnouncements={refetchAllAnnouncements}
            form={form}
            setForm={setForm}
          />
        </Box>
      )}
    </Box>
  );
};

export default AnnouncementPage;
