import { Box } from "@mui/material";
import Button from "components/common/Button";
import { Password } from "components/common/FormComponents";
import PopUp from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import { route } from "constants/routes";
import { useSearchQuery } from "hooks";
import useBtnDisableToggler from "hooks/useBtnDisableToggler";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { ResetPasswordType } from "types/api";
import { resetPassword, validateToken } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

export type ResetPasswordFormData = {
  newPassword: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const {
    control,
    formState,
    setError,
    getValues,
    handleSubmit,
    clearErrors,
    watch,
  } = useForm<ResetPasswordFormData>({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    criteriaMode: "all",
    mode: "onTouched",
  });
  const [isDisabled] = useBtnDisableToggler({ formState, watch });
  const [openModal, setOpenModal] = useState(false);
  const queryParams = useSearchQuery();
  const history = useHistory();
  const token: string = queryParams.get("token");
  const { password: passwordRegex } = regex;

  const { mutate: isTokenValid } = useMutation(
    ["tokenValidation"],
    () => validateToken({ token }),
    {
      onError: () => {
        history.replace(route.signIn.path);
      },
    },
  );

  const resetPass = useMutation(
    "ResetPassword",
    (data: ResetPasswordType) => resetPassword(data),
    {
      onSuccess: (res) => {
        setOpenModal(true);
      },
      onError: (err) => {
        setError("confirmPassword", { message: err["response"].data.message });
      },
    },
  );

  const onSubmit = handleSubmit((data) => {
    resetPass.mutate(
      getTrimmedData({
        password: data.confirmPassword,
        token: token,
      }),
    );
  });

  const passwordError = formState.errors.confirmPassword;

  useEffect(() => {
    if (passwordError) setError("newPassword", {});
    else clearErrors("newPassword");
  }, [passwordError, setError, clearErrors]);

  useEffect(() => {
    isTokenValid();
  }, []);

  if (!token) {
    history.replace(route.signIn.path);
  }

  const handleClose = () => {
    history.replace(route.signIn.path);
    setOpenModal(false);
  };

  return (
    <>
      <PopUp
        open={openModal}
        handleClose={handleClose}
        heading="Password Updated"
        actionLeft={{
          hidden: true,
        }}
        actionRight={{
          label: "Okay",
        }}
        handleRightBtnClick={handleClose}
      >
        <SimplePopUp description="Your password has been successfully changed." />
      </PopUp>
      <Box sx={styles.wrapper}>
        <Box sx={styles.resetPassword}>Reset Password</Box>
        <Box sx={styles.heading}>Please choose a new password.</Box>
        <Box component="form" onSubmit={onSubmit}>
          <Password
            name="newPassword"
            label="New Password"
            errors={formState.errors}
            control={control}
            customStyles={styles.input}
            rules={{
              required: errorMessage.required,
              validate: {
                isValid: (value) =>
                  value &&
                  passwordRegex.isUpper.test(value.trim().toString()) &&
                  value.length >= 8 &&
                  passwordRegex.isLower.test(value.trim().toString()) &&
                  passwordRegex.isNum.test(value.trim().toString()) &&
                  passwordRegex.isSpecial.test(value.trim().toString()),
              },
            }}
          />

          <Password
            name="confirmPassword"
            label="Confirm Password"
            errors={formState.errors}
            control={control}
            customStyles={styles.input}
            showHelperText={true}
            getValues={getValues}
            rules={{
              required: errorMessage.required,
              validate: {
                isSame: (value) =>
                  (value.trim() === getValues("newPassword").trim() &&
                    !!value) ||
                  errorMessage.invalidPassword,
                isValid: (value) =>
                  (value &&
                    passwordRegex.isUpper.test(value.trim().toString()) &&
                    value.length >= 8 &&
                    passwordRegex.isLower.test(value.trim().toString()) &&
                    passwordRegex.isNum.test(value.trim().toString()) &&
                    passwordRegex.isSpecial.test(value.trim().toString())) ||
                  errorMessage.password,
              },
            }}
          />
          <Button
            type="submit"
            label="Continue"
            customStyles={styles.continueBtn}
            disabled={isDisabled}
          />
        </Box>
        <Button
          as="FormButton"
          label="Sign In"
          onClick={() => history.push(route.signIn.path)}
        />
      </Box>
    </>
  );
};

export default ResetPassword;
