import Appointments from "components/feature/Appointments";
import NewPassword from "components/feature/NewPassword";
import SignUp from "components/feature/SignUp";
import Announcements from "pages/announcements";
import Authentication from "pages/authentication";
import Dashboard from "pages/dashboard";
import Events from "pages/events";
import ForgotPassword from "pages/forgot-password";
import HelpAndSupport from "pages/help-and-support";
import ManageResidents from "pages/manage-residents";
import ResetPassword from "pages/reset-password";
import SignIn from "pages/sign-in";
import Staff from "pages/staff";
import TermsAndConditions from "pages/terms-and-conditions";
import UserProfile from "pages/user-profile";

type Route = {
  [key: string]: {
    path: string;
    component: () => JSX.Element;
  };
};

export const route: Route = {
  signIn: {
    path: "/sign-in",
    component: SignIn,
  },
  userProfile: {
    path: "/user-profile",
    component: UserProfile,
  },
  forgotPassword: {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  resetPassword: {
    path: "/reset-password",
    component: ResetPassword,
  },
  manageResidents: {
    path: "/",
    component: ManageResidents,
  },
  events: {
    path: "/events",
    component: Events,
  },
  announcements: {
    path: "/announcements",
    component: Announcements,
  },
  dashboard: {
    path: "/dashboard",
    component: Dashboard,
  },
  staff: {
    path: "/staff",
    component: Staff,
  },
  helpAndSupport: {
    path: "/help-and-support",
    component: HelpAndSupport,
  },
  authentication: {
    path: "/authentication",
    component: Authentication,
  },
  newPassword: {
    path: "/new-password",
    component: NewPassword,
  },
  signUp: {
    path: "/sign-up",
    component: SignUp,
  },
  terms: {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
  appointments: {
    path: "/appointments",
    component: Appointments,
  },
};

const routesData = {
  defaultRoutes: [
    route.appointments,
    route.userProfile,
    route.announcements,
    route.helpAndSupport,
    route.events,
    route.manageResidents,
    route.dashboard,
  ],
  authRoutes: [
    route.signIn,
    route.signUp,
    route.forgotPassword,
    route.resetPassword,
    route.staff,
    route.authentication,
    route.newPassword,
  ],
  terms: route.terms,
};

export default routesData;
