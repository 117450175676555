import { Box } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./styles";

type AuthLayoutProps = {
  children?: ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.main}>
        <Box
          sx={styles.image}
          component="img"
          src="/images/gerryLogo.png"
          alt="Gerry"
        />
      </Box>
      <Box sx={styles.contentWrapper} className="children">
        <Box sx={styles.childWrapper}>{children}</Box>
        <Box sx={styles.footer}>
          <Box>
            {` By clicking Sign In you agree to Gerry’s `}
            <Link to="terms-and-conditions" target="_blank" className="link">
              Terms & Conditions
            </Link>
          </Box>
          <Box className="description">
            {`To learn more about how Gerry collects and uses protects your resident's information, please read Gerry’s `}
            <Box
              component="a"
              href="https://mygerry.com/privacy.html"
              target="_blank"
              className="link"
            >
              Privacy Policy
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
