import { Box, IconButton, Popover } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../dateFunction";
import styles from "./styles";

type CalendarProps = {
  setDate: (val: string) => void;
  minDate?: string;
  maxDate?: string;
  readOnly?: boolean;
  noSelected?: boolean;
};

const CalendarIcon = () => {
  return (
    <Box
      sx={styles.calendarIcon}
      component="img"
      src="/icons/calendar.svg"
      alt="calendar-icon"
    />
  );
};

const Calendar = ({
  setDate,
  readOnly,
  minDate = "",
  maxDate = "",
  noSelected,
}: CalendarProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [calendarAnchor, setCalendarAnchor] = useState<HTMLElement | null>(
    null,
  );

  const startRange = new Date(minDate);
  const endRange = new Date(maxDate);

  useEffect(() => {
    if (minDate) {
      setStartDate(new Date(minDate));
    }
  }, []);

  const onChangeHandler = (date) => {
    setStartDate(date);
    setDate(formatDate(date));
    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setCalendarAnchor(null);
  };

  const open = !!calendarAnchor;
  const id = open ? "calendar-popover" : undefined;

  return (
    <Box>
      <IconButton onClick={handleClick} disabled={readOnly}>
        <CalendarIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={calendarAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={styles.calendarWrapper}
      >
        <DatePicker
          selected={noSelected ? null : startDate}
          startDate={startRange}
          endDate={endRange}
          onChange={(date) => onChangeHandler(date)}
          minDate={new Date()}
          inline
        />
      </Popover>
    </Box>
  );
};

export default Calendar;
