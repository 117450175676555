import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    display: "flex",
    alignItems: "stretch",
    width: "100%",
    height: "100%",
    padding: {
      xl: "0",
      lg: "0",
      md: "0",
      sm: "0",
      xs: "96px 0 60px 0",
    },

    flexDirection: {
      xl: "row",
      lg: "row",
      md: "row",
      sm: "row",
      xs: "column",
    },

    "& .children": {
      backgroundColor: {
        xl: "custom.background.main",
        lg: "custom.background.main",
        md: "custom.background.main",
        sm: "custom.background.main",
        xs: "white",
      },
    },
  },

  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "50%",
    height: "100vh",
    marginTop: {
      xl: "0",
      lg: "0",
      md: "0",
      sm: "0",
      xs: "25px",
    },
  },

  contentWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "50%",
    height: "100vh",
    flexDirection: "column",
    marginTop: {
      xl: "0",
      lg: "0",
      md: "0",
      sm: "0",
      xs: "25px",
    },
  },

  childWrapper: {
    display: "flex",
    flexBasis: "100%",
    marginTop: {
      xl: "150px",
      lg: "150px",
      md: "150px",
      sm: "0",
      xs: "0",
    },
  },

  image: {
    height: {
      xl: "140px",
      lg: "140px",
      md: "140px",
      sm: "140px",
      xs: "56px",
    },
    width: {
      xl: "312px",
      lg: "312px",
      md: "312px",
      sm: "312px",
      xs: "125px",
    },
  },

  footer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    maxWidth: "500px",
    padding: "20px 10px 0px 10px",
    "& .description": {
      marginTop: "15px",
    },
    "& .link": {
      color: "black",
    },
    marginBottom: "50px",
  },
};

export default styles;
