import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/common/DataGrid";
import Info from "components/common/Info";
import PopUp, { Action } from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import SearchBar from "components/common/SearchField";
import Toggle from "components/common/Toggle";
import { errorModal } from "constants/dummyData";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  setCareHomeTimeZone,
  setResidentsRowData,
  setGlobalInfoData,
  setMrModalView,
} from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { InfoData, ManageResidentData } from "types/common";
import {
  getAllResidents,
  getCareHomeByPccLocal,
  getCareHomeInfo,
  updateResidentStatus,
  markResidentNotNew,
  fetchResidentMember,
} from "utils/api/user";
import { getInfoData, residentDataTransformer } from "utils/helper";
import Contacts from "./Contacts";
import InviteResident from "./InviteResident";
import styles from "./styles";

type Modal = "activate" | "invite" | "unableToFetch" | undefined;

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type ToggleResident = {
  props: {
    description: string;
    note?: string;
  };
} & ModalData;

export type InfoModal = {
  data: {
    name: string;
    email: string;
  }[];
} & ModalData;

const ResidentsDefault = () => {
  const {
    user: {
      residentsRowData,
      userDetails: { careHomes },
      careHomeTimeZone,
    },
  } = useAppSelector((state) => state);

  const pccId = careHomes?.[0]?.careHomeId;

  const [detailView, setView] = useState<boolean>(false);
  const [userData, setUserData] = useState();
  const [openModal, setOpenModal] = useState<Modal>(undefined);
  const [rowData, setRowData] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [residentData, setResidentData] = useState<any>({});
  const [totalData, setTotalData] = useState(0);
  const [residentId, setResidentId] = useState("");
  const [fetchContacts, setFetchContacts] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { data: careHomeData } = useQuery(
    ["getCareHome", pccId],
    () => getCareHomeByPccLocal(pccId),
    {
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { data: allResidents, refetch: refetchAllResidents } = useQuery(
    ["allResidents", page, search, pccId],
    () => getAllResidents({ pccId, page, search }),
    {
      onSuccess: (res) => {
        if (detailView) {
          const data = {
            ...residentsRowData,
            lastAction: res.data?.data?.find(
              (resident) => resident?.gerryId === residentsRowData?.gerryId,
            )?.lastAction,
          };
          dispatch(setResidentsRowData(data));
          handleDetailView(data);
        }
        setTotalData(res?.data?.metadata?.total);
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { data: residentInfo, refetch: refetchResidentInfo } = useQuery(
    ["residentInfo", pccId],
    () => getCareHomeInfo(pccId),
    {
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { mutate, isLoading } = useMutation(
    "statusToggle",
    (data: { pccId: string; id: string; isActive: boolean }) =>
      updateResidentStatus(data),
    {
      onSuccess: () => {
        refetchResidentInfo();
        refetchAllResidents();
        handleClose();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { mutate: markResNotNew } = useMutation(
    "markNotNew",
    (id: string) => markResidentNotNew(id),
    {
      onSuccess: () => {},
      onError: (err) => {},
      retry: 1,
    },
  );

  const { mutate: fetchResidentMutate } = useMutation(
    "addAMember",
    (residentId: string) => fetchResidentMember(residentId),
    {
      onSuccess: () => {
        setFetchContacts(true);
      },
      onError: (err) => {
        if (err["response"]?.data?.statusCode !== 429) {
          alert("Not enough quota limit for this request");
        }
      },
    },
  );
  useEffect(() => {
    refetchAllResidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const careHomeInfo = careHomeData?.data?.data;

  const address = [
    careHomeInfo?.address?.addressLine1,
    careHomeInfo?.address?.city,
    careHomeInfo?.address?.province,
    careHomeInfo?.address?.country,
  ]
    ?.filter((item) => item && item !== "")
    ?.join(", ");

  const infoData: InfoData = useMemo(
    () => ({
      heading: careHomeInfo?.name,
      content: [
        {
          label: "Address:",
          value: address,
        },
        {
          label: "Admin Emails:",
          value: "View All",
          action: true,
        },
        {
          label: "Residents:",
          value: `${
            residentInfo?.data?.data?.totalResidents || 0
          }   |   Active:  ${
            residentInfo?.data?.data?.activeResidentCount || 0
          }   |   Inactive:  ${
            residentInfo?.data?.data?.inActiveResidentCount || 0
          }`,
        },
      ],
    }),
    [
      address,
      careHomeInfo?.name,
      residentInfo?.data?.data?.activeResidentCount,
      residentInfo?.data?.data?.inActiveResidentCount,
      residentInfo?.data?.data?.totalResidents,
    ],
  );

  useEffect(() => {
    if (!!(residentInfo && careHomeInfo)) {
      setInfoData(infoData);
      dispatch(
        setGlobalInfoData({ ...infoData, source: careHomeInfo?.source }),
      );
      dispatch(setCareHomeTimeZone(careHomeInfo.timeZone));
    }
  }, [careHomeInfo, dispatch, infoData, residentInfo]);

  const mrViewAllModal: InfoModal = useMemo(
    () => ({
      heading: "Admin Care Home",
      data: careHomeInfo?.adminEmails.map((data) => ({
        ...data,
        name: `${data?.firstName || ""} ${data?.lastName || "-"}`,
      })),
      actionLeft: {
        hidden: true,
      },
      actionRight: {
        hidden: true,
      },
    }),
    [careHomeInfo?.adminEmails],
  );

  useEffect(() => {
    mrViewAllModal && dispatch(setMrModalView(mrViewAllModal));
  }, [dispatch, mrViewAllModal]);

  const [info, setInfoData] = useState<InfoData>(infoData);

  const getSearchText = (text: string) => {
    const txt = text.trim().toLowerCase();
    setSearch(txt);
  };

  const handleDetailView = (data) => {
    setView(true);
    setUserData(data);
    setInfoData(getInfoData({ data, careHomeTimeZone }));
  };

  useEffect(() => {
    if (fetchContacts) {
      handleDetailView(residentData);
    }

    return setFetchContacts(false);
  }, [fetchContacts, handleDetailView, residentData]);

  const getDescWithName = (name, isActiveResident) => {
    return isActiveResident
      ? `Do you want to deactivate '${name}'?`
      : `Do you want to activate '${name}'?`;
  };
  const deactivateResident: ToggleResident = {
    heading: "Deactivate Resident",
    props: {
      description: "Are you sure you want to deactivate ‘Asus Nursing Home’?",
      note: "Note: The data streaming will be stopped for this resident’s family members.",
    },

    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Deactivate",
      color: "error",
      actionCustomStyles: {
        button: {
          backgroundColor: "#c0291d",
        },
      },
    },
  };

  const activateResident: ToggleResident = {
    heading: "Activate Resident",
    props: {
      description: "Do you want to activate ‘Asus Nursing Home’?",
      note: "",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Activate",
    },
  };

  const handleToggle = (data: ManageResidentData) => {
    setRowData(data);
    data.status
      ? setModalData({
          ...deactivateResident,
          props: {
            ...deactivateResident.props,
            description: getDescWithName(data.residentName, data.status),
          },
        })
      : setModalData({
          ...activateResident,
          props: {
            ...activateResident.props,
            description: getDescWithName(data.residentName, data.status),
          },
        });
    setOpenModal("activate");
  };

  const handleRightBtnClick = () => {
    mutate({ pccId, ...rowData });
  };

  const [modalData, setModalData] = useState(deactivateResident);

  const handleClose = () => {
    setOpenModal(undefined);
  };

  const renderResidentName = (params: GridCellParams) => {
    return (
      <Box
        key={`${params.row.id}-${params.row.id}`}
        sx={styles.tableLink}
        onClick={() => {
          setResidentData(params.row);
          dispatch(setResidentsRowData(params.row));
          setResidentId(params.row.id);
          fetchResidentMutate(params.row.id);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setResidentData(params.row);
            dispatch(setResidentsRowData(params.row));
            setResidentId(params.row.id);
            fetchResidentMutate(params.row.id);
          }
        }}
        tabIndex={0}
      >
        <Box sx={styles.nameWrapper}> {params.row.residentName}</Box>
      </Box>
    );
  };

  const renderStatus = (params: GridCellParams) => {
    return (
      <Box sx={styles.actionIcon}>
        <Toggle
          value={params.row.status}
          onChange={() => handleToggle(params.row)}
        />
      </Box>
    );
  };

  const renderInvite = (params: GridCellParams) => {
    return (
      <Box sx={styles.nameWrapper}>
        <Box
          sx={
            params.row.status && !!params?.row?.primaryMember?.firstName
              ? styles.tableLink
              : styles.tableLinkDisabled
          }
          onClick={() => params.row.isNew && markResNotNew(params.row.id)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              params.row.isNew && markResNotNew(params.row.id);
            }
          }}
        >
          <InviteResident
            params={params.row}
            type="resident"
            refetch={refetchAllResidents}
          />
        </Box>
        {params.row.isNew && (
          <Box>
            <Box sx={styles.newWrapper}>
              <Box sx={styles.isNewDot} />
              New
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const renderPrimary = (params: GridCellParams) => {
    return (
      <Box sx={styles.primaryContactWrapper}>
        <Box sx={styles.nameWrapper}>{params.row.primaryContact}</Box>
        {params.row.isPrimaryChanged && (
          <Box
            sx={styles.imgStyles}
            component="img"
            src="/icons/primary-change.png"
            alt="change-primary"
          />
        )}
      </Box>
    );
  };

  const residentColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "residentName",
      headerName: "Resident Name",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderResidentName,
    },
    {
      field: "gerryId",
      headerName: "Gerry ID",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Data",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderStatus,
    },
    {
      field: "primaryContact",
      headerName: "Primary Contact",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderPrimary,
    },
    {
      field: "invite",
      headerName: "Invite",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderInvite,
    },
  ];

  return (
    <Box sx={styles.wrapper}>
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleRightBtnClick}
          loading={isLoading}
        >
          <SimplePopUp
            description={modalData.props.description}
            note={modalData.props.note}
          />
        </PopUp>
      )}
      <Info
        content={info.content}
        heading={info.heading}
        state={true}
        mrViewAllModal={mrViewAllModal}
        updateState={() => {}}
        customStyles={{
          active: { cursor: "not-allowed" },
          inActive: { cursor: "not-allowed" },
        }}
        disabled
      />

      <Box sx={styles.tableWrapper}>
        {detailView ? (
          <>
            <Box sx={styles.formWrapper}>
              <Box sx={styles.backWrapper}>
                <Box
                  sx={styles.backStyles}
                  onClick={() => {
                    setInfoData(infoData);
                    setView(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setInfoData(infoData);
                      setView(false);
                    }
                  }}
                  tabIndex={0}
                >
                  <ArrowBackIosIcon sx={styles.backArrow} />
                  <Box>Back</Box>
                </Box>
              </Box>
              <Contacts
                contact={userData}
                refetchAllResidents={refetchAllResidents}
                residentId={residentId}
              />
            </Box>
          </>
        ) : (
          <>
            <Box sx={styles.searchBar}>
              <SearchBar getText={getSearchText} />
            </Box>
            <DataGrid
              rows={residentDataTransformer(allResidents?.data?.data) || []}
              columns={residentColumn}
              disableSelectionOnClick
              onPageChange={(pageNo) => setPage(pageNo + 1)}
              rowsPerPageOptions={[10]}
              pageSize={10}
              rowCount={totalData}
              loading={false}
              componentsProps={{
                toolbar: { showQuickFilter: true },
              }}
              emptyTable="No residents added."
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ResidentsDefault;
