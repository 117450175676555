import dataProvider from "dataProvider";
import { getTrimmedData } from "utils/helper";

let fetcher = dataProvider("adminInstance");

export const getAllEvents = async ({
  pccId,
  page,
  sortBy = "venue",
  order = 0,
}: {
  pccId: string;
  page: number;
  sortBy?: string;
  order?: number;
}) => {
  return fetcher.get(
    `/events?careHomeId=${pccId}&page=${page}&perPage=10&sort_by=${sortBy}&order=${order}`,
  );
};

export const getEventById = async ({ eventId }: { eventId: string }) => {
  return fetcher.get(`events/${eventId}`);
};

export const addEvent = async (data) => {
  return fetcher.post(`events`, getTrimmedData(data));
};

export const updateEvent = async ({
  eventId,
  updatedData,
}: {
  eventId: string;
  updatedData: any;
}) => {
  return fetcher.put(`events/${eventId}`, getTrimmedData(updatedData));
};

export const deleteEventById = async ({ eventId }: { eventId: string }) => {
  return fetcher.delete(`events/${eventId}`);
};

export const updateProfile = async ({
  id,
  data,
}: {
  id: string;
  data: {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    customRole: string;
  };
}) => {
  return fetcher.put(`user/${id}`, {
    ...data,
  });
};

export const changePassword = async (data: { newPassword: string }) => {
  return fetcher.post("user/create-new-password", data);
};
