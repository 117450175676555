import { ForgotPasswordFormData } from "components/feature/ForgotPassword";
import { ResetPasswordFormData } from "components/feature/ResetPassword";
import { SignInFormData } from "components/feature/SignIn";
import { ChangePrimaryContactFormData } from "components/feature/ManageResidents/Contacts";
import { EventFormData } from "components/feature/Events/Form";
import { useEffect, useState } from "react";
import { FormState, UseFormWatch } from "react-hook-form";

export type Forms =
  | SignInFormData
  | ForgotPasswordFormData
  | ResetPasswordFormData
  | ChangePrimaryContactFormData
  | EventFormData;

type UseBtnDisableToggler = {
  formState: FormState<Forms>;
  watch: UseFormWatch<any>;
};

const useBtnDisableToggler = ({ formState, watch }: UseBtnDisableToggler) => {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    setIsDisabled(
      !(
        Object.keys(formState.errors).every(
          (field) => formState.errors[field] === "",
        ) &&
        Object.keys(formState.dirtyFields).length ===
          Object.keys(watch()).length
      ),
    );
  }, [formState, watch]);

  return [isDisabled];
};

export default useBtnDisableToggler;
