import { Box, Divider } from "@mui/material";
import Header from "components/common/Header";
import { sidebarMenu } from "constants/common";
import { ReactNode } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styles, { MenuItem } from "./styles";

type DefaultLayoutProps = {
  children?: ReactNode;
};

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const location = useLocation();

  const sideBarStyleManager = [].includes(location.pathname);

  const isTermsAndCond = location.pathname === "/terms-and-conditions";

  return (
    <Box sx={styles.layout}>
      <Header />
      <Box sx={styles.wrapper}>
        {sidebarMenu.map((item) => item.route).includes(location.pathname) && (
          <Box sx={styles.left}>
            <Box sx={styles.sidebar}>
              {sidebarMenu.map(
                (item, idx) =>
                  item.name !== "" && (
                    <Box key={`${item.name}_${idx}`} sx={styles.container}>
                      <Box sx={styles.item}>
                        <Link to={item.route}>
                          <MenuItem
                            matchedRoute={location.pathname === item.route}
                          >
                            <Box
                              className="icon"
                              component="img"
                              src={item.icon}
                              alt={item.icon}
                            />
                            <Box sx={styles.option}>{item.name}</Box>
                          </MenuItem>
                        </Link>
                      </Box>
                      <Divider sx={styles.divider} />
                    </Box>
                  ),
              )}
              <Box sx={styles.footerWrapper}>
                <Box sx={styles.topSection}>
                  <Box
                    component="a"
                    href="https://mygerry.com/terms.html"
                    target="_blank"
                  >
                    Terms and Conditions
                  </Box>
                  <Box
                    component="a"
                    href="https://mygerry.com/privacy.html"
                    target="_blank"
                  >
                    Privacy Policy
                  </Box>
                </Box>
                <Box sx={styles.bottomSection}>
                  Gerry support numbers:
                  <Box sx={styles.phoneNoWrapper}>
                    <Box
                      sx={styles.phoneNo}
                      component="a"
                      href="tel:+16479380383"
                    >
                      6479380383
                    </Box>
                    or
                    <Box
                      sx={styles.phoneNo}
                      component="a"
                      href="tel:+16472481555"
                    >
                      6472481555
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            ...styles.right,
            padding: isTermsAndCond ? "70px 0" : "70px 28px 20px",
            marginLeft: sideBarStyleManager ? "0" : "200px",
          }}
        >
          <Box>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
