import { Box } from "@mui/material";
import Button from "components/common/Button";
import { Input, Password } from "components/common/FormComponents";
import errorMessage from "constants/errorMessage";
import { route } from "constants/routes";
import { useSearchQuery } from "hooks";
import useBtnDisableToggler from "hooks/useBtnDisableToggler";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { setRole, setSignUpEmail, setSignUpToken } from "redux/slices/user";
import { useAppDispatch } from "redux/store";
import { SignUpData } from "types/api";
import { signUp, signUpTokenValidation } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

export type SignInFormData = {
  email: string;
  temporaryPassword: string;
};

const SignUp = () => {
  const history = useHistory();
  const queryParams = useSearchQuery();
  const token: string = queryParams.get("token");
  const dispatch = useAppDispatch();

  useQuery(
    ["tokenValidation"],
    () => signUpTokenValidation({ token }),

    {
      onSuccess: (data) => {
        setValue("email", data.data.data.email, {
          shouldTouch: true,
          shouldDirty: true,
        });
        dispatch(setSignUpEmail(data.data.data.email));
        dispatch(setRole(data.data.data.type));
        dispatch(setSignUpToken(token));
      },
      onError: (err) => {
        setError("temporaryPassword", {
          message: err["response"]?.data?.message,
        });
        history.replace(route.signIn.path);
      },
      retry: 1,
    },
  );

  if (!token) {
    history.replace(route.signIn.path);
  }

  const { control, formState, handleSubmit, watch, setError, setValue } =
    useForm<SignInFormData>({
      defaultValues: {
        email: "",
        temporaryPassword: "",
      },
      criteriaMode: "all",
      mode: "onTouched",
    });

  const [isDisabled] = useBtnDisableToggler({ formState, watch });
  const [disabled] = useState(true);

  const adminLogin = useMutation("signUp", (data: SignUpData) => signUp(data), {
    onSuccess: (res) => {
      history.replace(route.newPassword.path);
    },
    onError: (err) => {
      setError("temporaryPassword", {
        message: errorMessage.inValidCredentials,
      });
    },
  });

  const onSubmit = (data: SignUpData) => {
    adminLogin.mutate(getTrimmedData({ ...data, token }));
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.signIn}>Sign In</Box>
      <Box sx={styles.heading}>
        Welcome! Please Sign In with your username and temporary password.
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          label="Username"
          control={control}
          errors={formState.errors}
          customStyles={styles.input}
          disabled
          rules={{
            required: errorMessage.required,
            pattern: {
              value: regex.email,
              message: errorMessage.invalidEmail,
            },
          }}
        />
        <Password
          name="temporaryPassword"
          label="Password"
          errors={formState.errors}
          control={control}
          customStyles={styles.input}
          rules={{
            required: errorMessage.required,
            validate: {
              isValid: (value) =>
                value.length >= 8 || errorMessage.inValidCredentials,
            },
          }}
        />
        <Button
          type="submit"
          label="Sign In"
          disabled={isDisabled && disabled}
          customStyles={styles.signInBtn}
        />
      </Box>
    </Box>
  );
};

export default SignUp;
