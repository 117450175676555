import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    margin: "0",
    padding: "0",
    "& .label": {
      fontSize: "14px",
      fontWeight: "bold",
      minWidth: "110px",
      color: "custom.text.main",
    },

    "& .labelPos": {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
  },

  uploadBtn: {
    display: "inline",
    fontSize: "12px",
    maxWidth: "fit-content",
    whiteSpace: "nowrap",
  },

  filesWrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },

  files: {
    color: "primary.main",
    fontSize: "12px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "6px 8px",
  },

  file: {
    marginRight: "16px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },

  hide: {
    display: "none",
  },

  delIcon: {
    paddingLeft: "4px",
    cursor: "pointer",
  },
};

export default styles;
