import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    height: "76vh",
    display: "flex",
    flexDirection: "column",
    gap: "26px",
    alignItems: "center",
    marginTop: "84px",
    borderBottom: "1px solid #056ee6",
  },

  contactBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "376px",
    height: "140px",
    backgroundColor: "#f3f5f8",

    "& .contact-boxTitle": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: "bold",
      gap: "5px",

      "& .email": {
        marginTop: "16px",
        color: "#484848",
      },
    },
  },

  link: {
    color: "black",
    textUnderline: "underline",
  },

  securityBox: {
    width: "376px",
    height: "140px",
    backgroundColor: "#f3f5f8",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "bold",
    "& .link": {
      color: "black",
    },
  },

  phoneWrapper: {
    display: "flex",
    gap: "4px",
    color: "#484848",
  },
};

export default styles;
