import { createSlice } from "@reduxjs/toolkit";

type AnnouncementData = {
  id: string;
  careHomeId: string;
  title: string;
  description: string;
  tags: string[];
  isPublished: boolean;
  publishedAt: Date;
  expireAt: string;
  announcedBy: string;
  careHome: {
    id: string;
    isActive: boolean;
    pccCode: string;
    name: string;
    phoneNumber: string;
  };
  announceBy: {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    email: string;
  };
};

interface Announcement {
  announcementData: AnnouncementData[];
  announcementRowData: { id: string; user: any };
}

const initialState: Announcement = {
  announcementData: [
    {
      id: "",
      careHomeId: "",
      title: "",
      description: "",
      tags: [],
      isPublished: true,
      publishedAt: null,
      expireAt: "",
      announcedBy: "",
      careHome: {
        id: "",
        isActive: true,
        pccCode: "",
        name: "",
        phoneNumber: "",
      },
      announceBy: {
        id: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        email: "",
      },
    },
  ],
  announcementRowData: { id: "", user: {} },
};

const announcementSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAnnouncementData: (state, action) => {
      state.announcementData = action.payload;
    },
    setAnnouncementRowData: (state, action) => {
      state.announcementRowData = action.payload;
    },
  },
});

export const { setAnnouncementData, setAnnouncementRowData } =
  announcementSlice.actions;

export default announcementSlice.reducer;
