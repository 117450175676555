import dataProvider from "dataProvider";

let fetcher = dataProvider("adminInstance");

export const getAllAnnouncement = async ({
  pccId,
  page,
  sortBy = "announcements",
  order = 0,
}: {
  pccId: string;
  page: number;
  sortBy?: string;
  order?: number;
}) => {
  return fetcher.get(
    `/announcements?careHomeId=${pccId}&page=${page}&perPage=10&sort_by=${sortBy}&order=${order}`,
  );
};

export const addNewAnnouncement = async (data) => {
  return fetcher.post(`announcements`, data);
};

export const updateAnnouncementById = async ({
  announcementId,
  updatedData,
}: {
  announcementId: string;
  updatedData: any;
}) => {
  return fetcher.put(`announcements/${announcementId}`, updatedData);
};

export const deleteAnnouncementId = async ({
  announcementId,
}: {
  announcementId: string;
}) => {
  return fetcher.delete(`announcements/${announcementId}`);
};
