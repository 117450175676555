import { Box } from "@mui/system";
import Button from "components/common/Button";
import { Input, Phone } from "components/common/FormComponents";
import errorMessage from "constants/errorMessage";
import { isEqual } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { setUser } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { updateProfile } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

type UserProfileFormData = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phoneNumber: string;
  password: string;
};

type ProfileProps = {
  changePassword: () => void;
};

const Profile = ({ changePassword }: ProfileProps) => {
  const [submitState, setSubmitState] = useState(true);

  const dispatch = useAppDispatch();

  const {
    user: {
      userDetails: { id },
    },
  } = useAppSelector((state) => state);

  const { mutate } = useMutation(
    ["updateInfo"],
    (data: {
      id: string;
      data: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        customRole: string;
      };
    }) => updateProfile(data),
    {
      onSuccess: (data) => {
        dispatch(setUser(data?.data?.data));
      },
    },
  );

  const {
    user: {
      userDetails: { firstName, lastName, email, phoneNumber, customRole },
    },
  } = useAppSelector((state) => state);

  // const role = careHomes?.[0]?.role?.name;

  const initialState = useMemo(
    () => ({
      firstName,
      lastName,
      email,
      customRole,
      phoneNumber,
    }),
    [firstName, lastName, email, customRole, phoneNumber],
  );

  const { control, formState, handleSubmit, watch, reset, getValues } =
    useForm<UserProfileFormData>(
      useMemo(
        () => ({
          defaultValues: {
            firstName,
            lastName,
            email,
            role: customRole,
            phoneNumber,
          },
          criteriaMode: "all",
          mode: "onChange",
        }),
        [customRole, email, firstName, lastName, phoneNumber],
      ),
    );

  // const [disableSubmitBtn] = useBtnDisableToggler({ formState, watch });

  const [isDisabled, setIsDisabled] = useState(true);

  const onSubmit = handleSubmit((data) => {
    mutate({
      id,
      data: {
        email: getValues().email,
        firstName: getValues().firstName,
        lastName: getValues().lastName,
        phoneNumber: getValues().phoneNumber,
        customRole: getValues().role,
      },
    });
    setIsDisabled(true);
  });

  const handleOnchange = () =>
    !isEqual(getTrimmedData(watch()), initialState)
      ? setSubmitState(false)
      : setSubmitState(true);

  const { email: emailRegex, contactNumber: contactRegex } = regex;
  const { errors } = formState;

  return (
    <Box sx={styles.mainWrapper}>
      <Box component="form" onChange={handleOnchange} onSubmit={onSubmit}>
        <Box sx={styles.wrapper}>
          <Input
            name="firstName"
            label="First Name"
            control={control}
            errors={errors}
            customStyles={styles.input}
            disabled={isDisabled}
            isAlpha
            rules={{
              required: errorMessage.required,
            }}
          />
          <Input
            name="lastName"
            label="Last Name"
            control={control}
            errors={errors}
            customStyles={styles.input}
            disabled={isDisabled}
            isAlpha
            rules={{
              required: errorMessage.required,
            }}
          />

          <Input
            name="email"
            label="Email ID"
            control={control}
            errors={errors}
            customStyles={styles.input}
            disabled
            rules={{
              required: errorMessage.required,
              pattern: {
                value: emailRegex,
                message: errorMessage.invalidEmail,
              },
            }}
          />

          <Phone
            name="phoneNumber"
            label="Contact Number"
            disabled={isDisabled}
            control={control}
            errors={errors}
            customStyles={styles.contactInput}
            rules={{
              required: errorMessage.required,
              pattern: {
                value: contactRegex,
                message: errorMessage.invalidContact,
              },
            }}
          />

          <Box sx={styles.roleWrapper}>
            <Input
              name="role"
              label="Role"
              control={control}
              errors={errors}
              customStyles={styles.input}
              disabled={isDisabled}
              rules={{
                required: errorMessage.required,
              }}
            />
            {!isDisabled && (
              <Box
                sx={{
                  ...styles.changePasswordWrapper,
                  bottom: errors?.role ? "-27px" : "-12px",
                }}
              >
                <Button
                  as="FormButton"
                  label="Change Password"
                  onClick={() => changePassword()}
                  sx={styles.changePasswordButton}
                />
              </Box>
            )}
          </Box>
        </Box>

        {isDisabled ? (
          <Button
            label="Edit"
            customStyles={styles.edit}
            onClick={() => setIsDisabled(false)}
          />
        ) : (
          <Box sx={styles.editButtonWrapper}>
            <Button
              label="Cancel"
              customStyles={styles.cancelButton}
              onClick={() => {
                setIsDisabled(true);
                reset(initialState);
              }}
            />
            <Button
              label="Save"
              type="submit"
              customStyles={styles.saveButton}
              disabled={submitState}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
