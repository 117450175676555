import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    backgroundColor: "custom.background.gray",
    padding: "20px 40px",
    margin: "9px 0",
    "& a": {
      textDecoration: "underline !important",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "5px",
  },

  sub: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "4px",
    fontSize: "15px",
    fontWeight: "500",
  },

  view: {
    background: "transparent",
    boxShadow: "none",
    color: "primary.main",
    textDecoration: "underline",
    padding: "0",
    "&:hover": {
      background: "transparent",
      boxShadow: "none",
      textDecoration: "underline",
    },
  },

  toggleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 500,
    color: "black",
    gap: "3px",
  },
};

export default styles;
