export const convert = (dateStr: string) => {
  let date = new Date(dateStr),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join("-");
};

export const formatDate = (date: Date) => {
  const newDate = new Date(date).toDateString().split(" ");
  const month = date.toLocaleString("default", { month: "long" });
  return `${month} ${newDate[2]}, ${newDate[3]}`;
};

export const getCurrDate = (date: Date | "") => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const newDate = date === "" ? new Date() : new Date(date);

  const day = ("0" + newDate.getDate()).slice(-2);
  const mon = ("0" + (newDate.getMonth() + 1)).slice(-2);
  const year = newDate.getFullYear();

  return `${month[mon]} ${day}, ${year}`;
};
