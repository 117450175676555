import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    marginTop: "70px",
    padding: "0 20px",
  },

  iFrame: {
    position: "absolute",
    top: "0px",
    padding: "64px 0 8px",
    height: "100%",
    width: "100%",
  },
};

export default styles;
