import { createSlice } from "@reduxjs/toolkit";

type EventData = {
  name: string;
  id: string;
  venue: string;
  type: string | null;
  description: string;
  startDate: string;
  endDate: string;
  careHome: {
    id: string;
    isActive: boolean;
    pccCode: string;
    name: string;
    phoneNumber: string;
  };
  organizedBy: {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    email: string;
  };
};

interface Event {
  eventData: EventData[];
  eventRowData: any;
}

const initialState: Event = {
  eventData: [
    {
      id: "",
      type: null,
      name: "",
      description: "",
      venue: "",
      startDate: "",
      endDate: null,
      careHome: {
        id: "",
        isActive: true,
        pccCode: "",
        name: "",
        phoneNumber: "",
      },
      organizedBy: {
        id: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        email: "",
      },
    },
  ],
  eventRowData: { id: "", user: {} },
};

const eventSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
    setEventRowData: (state, action) => {
      state.eventRowData = action.payload;
    },
  },
});

export const { setEventData, setEventRowData } = eventSlice.actions;

export default eventSlice.reducer;
