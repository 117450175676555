import { Box } from "@mui/system";
import Button from "components/common/Button";
import { Input, Phone } from "components/common/FormComponents";
import errorMessage from "constants/errorMessage";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { loginUser } from "redux/actions";
import { setNewPassword } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { staffInfo } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

type StaffFormData = {
  firstName: string;
  lastName: string;
  email: string;
  customRole: string;
  phoneNumber: string;
};

const Staff = () => {
  const dispatch = useAppDispatch();
  const { signUpEmail, signUpToken, newPassword } = useAppSelector(
    (state) => state.user,
  );

  const history = useHistory();

  const { control, formState, handleSubmit } = useForm<StaffFormData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: signUpEmail,
      customRole: "",
      phoneNumber: "",
    },
    criteriaMode: "all",
    mode: "onTouched",
  });

  const { mutate: sendStaffInfo } = useMutation(
    "verifyOtp",
    (data: any) => staffInfo(data),
    {
      onSuccess: (data) => {
        dispatch(loginUser(data.data.data));
        dispatch(setNewPassword(""));
        history.replace("/");
      },
    },
  );

  const onSubmit = handleSubmit((data) => {
    sendStaffInfo(
      getTrimmedData({
        ...data,
        inviteToken: signUpToken,
        password: newPassword,
        // role,
      }),
    );
  });

  const { errors, isValid } = formState;

  return (
    <Box sx={styles.mainWrapper}>
      <Box sx={styles.header}>Tell us a little more about yourself.</Box>
      <Box component="form" onSubmit={onSubmit}>
        <Box sx={styles.wrapper}>
          <Input
            name="firstName"
            label="First Name"
            control={control}
            errors={errors}
            customStyles={styles.input}
            isAlpha
            rules={{
              required: true,
            }}
          />
          <Input
            name="lastName"
            label="Last Name"
            control={control}
            errors={errors}
            customStyles={styles.input}
            isAlpha
            rules={{
              required: true,
            }}
          />
          <Input
            name="email"
            label="Email ID"
            control={control}
            errors={errors}
            customStyles={styles.input}
            disabled
            rules={{
              required: true,
              pattern: {
                value: regex.email,
                message: errorMessage.invalidEmail,
              },
            }}
          />
          <Phone
            name="phoneNumber"
            label="Contact Number"
            control={control}
            errors={errors}
            customStyles={styles.input}
            rules={{
              required: true,
              pattern: {
                value: regex.contactNumber,
                message: errorMessage.invalidContact,
              },
            }}
          />
          <Input
            name="customRole"
            label="Role"
            control={control}
            errors={errors}
            customStyles={styles.input}
            rules={{
              required: true,
            }}
          />
        </Box>
        <Button
          type="submit"
          label="Continue"
          customStyles={styles.cancel}
          disabled={!isValid}
        />
      </Box>
    </Box>
  );
};

export default Staff;
