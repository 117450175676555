import { Styles } from "types/common";

const styles: Styles = {
  mainWrapper: {
    height: "69vh",
    display: "flex",
    flexDirection: "column",
    marginTop: "150px",

    "& .MuiInputBase-root": {
      width: "376px",
    },
  },

  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "22px",
    width: "376px",
    margin: "0 auto",
    justifyContent: "space-between",
  },

  profile: {
    width: "600px",
    fontSize: "20px",
    fontWeight: "bold",
    color: "custom.text.secondary",
    margin: "0 auto",
    marginBottom: "40px",
  },

  input: {
    flexBasis: "46%",
    "& .label": {
      color: "custom.text.secondary",
      position: "absolute",
    },
  },

  contactInput: {
    "& .label": {
      color: "custom.text.secondary",
      position: "absolute",
    },
    "& .MuiInputBase-root": {
      borderColor: "custom.border.main",
    },
  },

  roleWrapper: {
    position: "relative",
  },

  changePasswordWrapper: {
    position: "absolute",
    bottom: "-12px",
  },

  changePasswordButton: {
    minWidth: "150px",
    height: "28px",
    background: "transparent",
    color: "primary.main",
    boxShadow: "none",
    textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      textDecoration: "underline",
    },
  },

  edit: {
    display: "flex",
    justifyContent: "center",
    width: "120px",
    margin: "0 auto",
    height: "40px",
    marginTop: "40px",
    backgroundColor: "primary.dark",
  },

  editButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    columnGap: "15px",
    marginTop: "40px",
  },

  cancelButton: {
    width: "120px",
    height: "40px",
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.background.darkGray",
    color: "custom.text.secondary",
    "&:hover": {
      backgroundColor: "white",
    },
  },

  saveButton: {
    width: "120px",
    height: "40px",
    backgroundColor: "primary.dark",
  },
};

export default styles;
