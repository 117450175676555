import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "260px",
    "& .MuiFormHelperText-root": {
      bottom: "-40px",
    },
  },

  resetPassword: {
    fontSize: {
      xl: "30px",
      lg: "30px",
      md: "30px",
      sm: "30px",
      xs: "25px",
    },
    fontWeight: "bold",
    textAlign: {
      xl: "left",
      lg: "left",
      md: "left",
      sm: "left",
      xs: "center",
    },
    whiteSpace: "nowrap",
  },

  heading: {
    marginTop: "16px",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: {
      xl: "left",
      lg: "left",
      md: "left",
      sm: "left",
      xs: "center",
    },
  },

  signInLink: {
    textDecoration: "underline",
    fontSize: "12px",
    fontWeight: "bold",
    color: "primary.main",
    cursor: "pointer",
    textAlign: "center",
  },

  input: {
    marginTop: "18px",
  },

  continueBtn: {
    marginTop: "54px",
    marginBottom: "42px",
    width: "100%",
    backgroundColor: "primary.dark",
  },
};

export default styles;
