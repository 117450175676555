import { Styles } from "types/common";

const styles: Styles = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "bold",
    justifyContent: "center",
  },

  header: {
    marginBottom: "20px",
    textAlign: {
      xl: "left",
      lg: "left",
      md: "left",
      sm: "left",
      xs: "center",
    },
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  input: {
    width: "100%",
    "& .label": {
      position: "absolute",
    },
    "& .MuiInputBase-root": {
      width: "100% ",
    },
  },

  cancel: {
    marginTop: "40px",
    height: "40px",
    backgroundColor: "primary.dark",
  },
};

export default styles;
