import { Button } from "@mui/material";
import { ComponentProps } from "react";
import {
  AnnoucementTableData,
  AnnouncementFormInputs,
  AnnouncementModalData,
  EventTableData,
  FamilyContactTableData,
  InfoData,
  ManageHome,
  ManageResidentData,
  ManageResidentModal,
  PrimaryContactTableData,
  SelectOptions,
  TableHeadType,
} from "types/common";

export const manageResidentsTableHead: TableHeadType[] = [
  { name: "Resident Name", isSortable: true, type: "string" },
  { name: "Gerry ID", isSortable: true, type: "string" },
  { name: "Status", isSortable: true, type: "string" },
  { name: "Primary Contact" },
  { name: "Invite" },
];

export type Action = {
  label?: string;
} & ComponentProps<typeof Button>;

export const errorModal: {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
  props: {
    description: string;
    note: string;
  };
} = {
  heading: "Unable to fetch results",
  props: {
    description:
      "We are unable to fetch the data from PointClickCare at the moment.",
    note: "",
  },
  actionLeft: {
    hidden: true,
  },
  actionRight: {
    hidden: true,
  },
};

export const manageResidentData: ManageResidentData[] = [
  {
    residentName: "John Smith",
    gerryId: "ACESI312",
    status: true,
    primaryContact: "Ketty Smith",
    invite: "Send Invite",
    primary: [
      {
        name: "Samantha Joe",
        email: "samanthajoe@user.com",
        status: true,
        invite: "Change Primary Contact",
      },
      {
        name: "Ketty Smith",
        email: "kettysmith@user.com",
        status: false,
        invite: "",
      },
    ],
    family: [
      {
        name: "John Smith",
        email: "johnsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
      {
        name: "Lauren Smith",
        email: "laurensmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Trinity Smith",
        email: "trinitysmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Omar Smith",
        email: "omarsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
    ],
  },
  {
    residentName: "Samantha Jon",
    gerryId: "BNOVS678",
    status: true,
    primaryContact: "Carol Jon",
    invite: "Send Invite",
    primary: [
      {
        name: "Samantha Jon",
        email: "samanthajoe@user.com",
        status: true,
        invite: "Change Primary Contact",
      },
      {
        name: "Ketty Jon",
        email: "kettysmith@user.com",
        status: false,
        invite: "",
      },
    ],
    family: [
      {
        name: "John Jon",
        email: "johnsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
      {
        name: "Lauren Jon",
        email: "laurensmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Trinity Jon",
        email: "trinitysmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Omar Jon",
        email: "omarsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
    ],
  },
  {
    residentName: "Hunter Steede",
    gerryId: "ACESI312",
    status: false,
    primaryContact: "Lauren Hollingsworth",
    invite: "Send Invite",
    primary: [
      {
        name: "Samantha Steede",
        email: "samanthajoe@user.com",
        status: true,
        invite: "Change Primary Contact",
      },
      {
        name: "Ketty Steede",
        email: "kettysmith@user.com",
        status: false,
        invite: "",
      },
    ],
    family: [
      {
        name: "John Steede",
        email: "johnsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
      {
        name: "Lauren Steede",
        email: "laurensmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Trinity Steede",
        email: "trinitysmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Omar Steede",
        email: "omarsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
    ],
  },
  {
    residentName: "Nancy Hollingsworth",
    gerryId: "BNOVS678",
    status: false,
    primaryContact: "Trinity Harpor",
    invite: "Send Invite",
    primary: [
      {
        name: "Samantha Hollingsworth",
        email: "samanthajoe@user.com",
        status: true,
        invite: "Change Primary Contact",
      },
      {
        name: "Ketty Hollingsworth",
        email: "kettysmith@user.com",
        status: false,
        invite: "",
      },
    ],
    family: [
      {
        name: "John Hollingsworth",
        email: "johnsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
      {
        name: "Lauren Hollingsworth",
        email: "laurensmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Trinity Hollingsworth",
        email: "trinitysmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Omar Hollingsworth",
        email: "omarsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
    ],
  },
  {
    residentName: "Samantha Jon",
    gerryId: "BNOVS678",
    status: true,
    primaryContact: "Carol Jon",
    invite: "Send Invite",
    primary: [
      {
        name: "Samantha Jon",
        email: "samanthajoe@user.com",
        status: true,
        invite: "Change Primary Contact",
      },
      {
        name: "Ketty Jon",
        email: "kettysmith@user.com",
        status: false,
        invite: "",
      },
    ],
    family: [
      {
        name: "John Jon",
        email: "johnsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
      {
        name: "Lauren Jon",
        email: "laurensmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Trinity Jon",
        email: "trinitysmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Omar Jon",
        email: "omarsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
    ],
  },
  {
    residentName: "Hunter Steede",
    gerryId: "ACESI312",
    status: false,
    primaryContact: "Lauren Hollingsworth",
    invite: "Send Invite",
    primary: [
      {
        name: "Samantha Jon",
        email: "samanthajoe@user.com",
        status: true,
        invite: "Change Primary Contact",
      },
      {
        name: "Ketty Jon",
        email: "kettysmith@user.com",
        status: false,
        invite: "",
      },
    ],
    family: [
      {
        name: "John Jon",
        email: "johnsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
      {
        name: "Lauren Jon",
        email: "laurensmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Trinity Jon",
        email: "trinitysmith@user.com",
        status: false,
        invite: "Send Invite",
      },
      {
        name: "Omar Jon",
        email: "omarsmith@user.com",
        status: true,
        invite: "Send Invite",
      },
    ],
  },
];

export const primaryContactTableHead: TableHeadType[] = [
  { name: "Primary Contact" },
  { name: "Email ID" },
  { name: "Status" },
  { name: "Invite" },
];

export const primaryContactTableData: PrimaryContactTableData[] = [
  {
    name: "Samantha Joe",
    email: "samanthajoe@user.com",
    status: true,
    invite: "Change Primary Contact",
  },
  {
    name: "Ketty Smith",
    email: "kettysmith@user.com",
    status: false,
    invite: "",
  },
];

export const familyContactTableHead: TableHeadType[] = [
  { name: "Family Member" },
  { name: "Email ID" },
  { name: "Status" },
  { name: "" },
];

export const familyContactTableData: FamilyContactTableData[] = [
  {
    name: "John Smith",
    email: "johnsmith@user.com",
    status: true,
    invite: "Send Invite",
  },
  {
    name: "Lauren Smith",
    email: "laurensmith@user.com",
    status: false,
    invite: "Send Invite",
  },
  {
    name: "Trinity Smith",
    email: "trinitysmith@user.com",
    status: false,
    invite: "Send Invite",
  },
  {
    name: "Omar Smith",
    email: "omarsmith@user.com",
    status: true,
    invite: "Send Invite",
  },
];

export const infoData: InfoData = {
  heading: "Maynard Nursing Home",
  content: [
    {
      label: "Address:",
      value: "290 Yonge Street, North York, Ontario M5V 2W8",
    },
    {
      label: "Admin Emails:",
      value: "View All",
      action: true,
    },
    {
      label: "Residents: 64   |   Active: 34   |   Inactive: 18 ",
      value: "",
    },
  ],
};

export const announcementTableHead: TableHeadType[] = [
  { name: "Announcements", isSortable: false },
  { name: "Tags", isSortable: false },
  { name: "Date", isSortable: false },
  { name: "Time", isSortable: false },
  { name: "Action", isSortable: false },
];

export const announcementTableData: AnnoucementTableData[] = [
  {
    announcements: {
      name: "Swimming pool is under maintenance. and other reasons",
      isLink: true,
    },
    tags: {
      name: "General, Protocol, COVID, Ipsum ...",
    },
    description: {
      name: "Regular cleaning and hygiene maintenance work",
    },
    date: {
      name: "Mar 22, 2022",
    },
    time: {
      name: "4:00 PM",
    },
    action: {
      name: "",
    },
  },
  {
    announcements: {
      name: "Left wing lift closed for maintenance",
      isLink: true,
    },
    tags: {
      name: "General, Protocol, COVID, Ipsum",
    },
    date: {
      name: "Mar 22, 2022",
    },
    description: {
      name: "Lift often stuck in bwtween So, maintenance work regarding the same is in progress",
    },
    time: {
      name: "4:00 PM",
    },
    action: {
      name: "",
    },
  },
  {
    announcements: {
      name: "Only gate no 2 is open for security purpose",
      isLink: true,
    },
    tags: {
      name: "General, Protocol, COVID, Ipsum ",
    },
    date: {
      name: "Mar 22, 2022",
    },
    description: {
      name: "Due to ongoing something, I dont know we just closed all the gates except gate no 2. please cooperate even if you dont want to.",
    },
    time: {
      name: "4:00 PM",
    },
    action: {
      name: "",
    },
  },
];

export const announcementFormData: AnnouncementFormInputs = {
  subject: "Games and Snacks",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse felis imperdiet nunc consequat eu feugiat aliquet sed pharetra. Ullamcorper ultricies netus cursus urna tellus. Ac amet ac nunc eu in dignissim et. Nisl vitae viverra cursus sit amet nunc elementum. Ornare cursus netus scelerisque vulputate. Duis orci, feugiat faucibus elit eget pellentesque in in. Nibh bibendum diam at facilisi tristique. Ac faucibus et morbi vulputate cursus sit. In at mollis sollicitudin diam imperdiet tellus morbi id. Eros, ut aliquam vestibulum auctor velit. Duis morbi enim odio placerat porttitor blandit. Id dictum pharetra posuere id lorem blandit. Adipiscing bibendum tristique sed morbi nec. Vulputate at suspendisse donec sagittis eget senectus varius. ",
  tags: [],
  attachments: [],
};

export const selectOptions: SelectOptions[] = [
  { label: "covid", value: "covid" },
  { label: "general", value: "general" },
  { label: "protocol", value: "protocol" },
];

export const eventTableHead: TableHeadType[] = [
  { name: "Event", isSortable: true },
  { name: "Venue", isSortable: true },
  { name: "Date", isSortable: true },
  { name: "Time", isSortable: true },
  { name: "Action", isSortable: false },
];

export const eventTableData: EventTableData[] = [
  {
    event: {
      name: "Games & Snacks",
      isLink: true,
    },
    venue: {
      name: "Visitors room, 2nd Floor",
    },
    date: {
      name: "Mar 22, 2022",
    },

    time: {
      name: "4:00 PM",
    },
    action: {
      name: "",
    },
  },
  {
    event: {
      name: "Annual Comic Meet",
      isLink: true,
    },
    venue: {
      name: "Society Hall",
    },
    date: {
      name: "Mar 22, 2022",
    },

    time: {
      name: "04:00 PM",
    },
    action: {
      name: "",
    },
  },
  {
    event: {
      name: "Society Foundation Day",
      isLink: true,
    },
    venue: {
      name: "Society Compound",
    },
    date: {
      name: "Mar 22, 2022",
    },

    time: {
      name: "04:00 PM",
    },
    action: {
      name: "",
    },
  },
  {
    event: {
      name: "Games & Snacks",
      isLink: true,
    },
    venue: {
      name: "Visitors room, 2nd Floor",
    },
    date: {
      name: "Mar 22, 2022",
    },

    time: {
      name: "04:00 PM",
    },
    action: {
      name: "",
    },
  },
  {
    event: {
      name: "Games & Snacks",
      isLink: true,
    },
    venue: {
      name: "Visitors room, 2nd Floor",
    },
    date: {
      name: "Mar 22, 2022",
    },

    time: {
      name: "4:00 PM",
    },
    action: {
      name: "",
    },
  },
  {
    event: {
      name: "Games & Snacks",
      isLink: true,
    },
    venue: {
      name: "Visitors room, 2nd Floor",
    },
    date: {
      name: "Mar 22, 2022",
    },

    time: {
      name: "4:00 PM",
    },
    action: {
      name: "",
    },
  },
];

export const manageHomeTableHead: TableHeadType[] = [
  { name: "Care Home Name", isSortable: true },
  { name: "City", isSortable: true },
  { name: "Prov./St.", isSortable: true },
  { name: "Country", isSortable: true },
  { name: "Invite" },
  { name: "Status", isSortable: true },
];

export const manageHome: ManageHome[] = [
  {
    homeName: {
      name: "Care Home Name 1",
      isLink: true,
    },
    city: {
      name: "Ottawa",
    },
    prov: {
      name: "Ontario",
    },
    country: {
      name: "Canada",
    },
    invite: {
      name: "Invite",
      isLink: true,
    },
    status: {
      name: "",
      status: true,
    },
  },
  {
    homeName: {
      name: "Care Home Name 2",
      isLink: true,
    },
    city: {
      name: "Toronto",
    },
    prov: {
      name: "Ontario",
    },
    country: {
      name: "Canada",
    },
    invite: {
      name: "Invite",
      isLink: true,
    },
    status: {
      name: "",
      status: false,
    },
  },
  {
    homeName: {
      name: "Care Home Name 3",
      isLink: true,
    },
    city: {
      name: "Montreal",
    },
    prov: {
      name: "Quebec",
    },
    country: {
      name: "Canada",
    },
    invite: {
      name: "Invite",
      isLink: true,
    },
    status: {
      name: "",
      status: true,
    },
  },
  {
    homeName: {
      name: "Care Home Name 4",
      isLink: true,
    },
    city: {
      name: "Vancouver",
    },
    prov: {
      name: "British Columbia",
    },
    country: {
      name: "Canada",
    },
    invite: {
      name: "Invite",
      isLink: true,
    },
    status: {
      name: "",
      status: true,
    },
  },
  {
    homeName: {
      name: "Care Home Name 5",
      isLink: true,
    },
    city: {
      name: "Vancouver",
    },
    prov: {
      name: "British Columbia",
    },
    country: {
      name: "Canada",
    },
    invite: {
      name: "Resend Invite",
      isLink: true,
    },
    status: {
      name: "",
      status: true,
    },
  },
  {
    homeName: {
      name: "Care Home Name",
      isLink: true,
    },
    city: {
      name: "Vancouver",
    },
    prov: {
      name: "British Columbia",
    },
    country: {
      name: "Canada",
    },
    invite: {
      name: "Resend Invite",
      isLink: true,
    },
    status: {
      name: "",
      status: true,
    },
  },
];

export const createAnnouncementModal: ManageResidentModal = {
  heading: "Create Announcement",
  content: {
    type: "simple",
    props: {
      description:
        "Are you sure you want to create and post this announcement?",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Yes",
};

export const editAnnouncementModal: ManageResidentModal = {
  heading: "Edit Announcement",
  content: {
    type: "simple",
    props: {
      description: "Are you sure you want to edit and post this announcement?",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Yes",
};

export const announcementModalData: AnnouncementModalData = {
  delete: {
    heading: "Delete Announcement",
    content: {
      type: "simple",
      props: {
        description: "Are you sure you want to delete this announcement?",
      },
    },
    leftLabel: "Cancel",
    rightLabel: "Yes",
  },
  create: {
    heading: "Create Announcement",
    content: {
      type: "simple",
      props: {
        description:
          "Are you sure you want to create and post this announcement?",
      },
    },
    leftLabel: "Cancel",
    rightLabel: "Yes",
  },
};
export const delAnnouncementModal: ManageResidentModal = {
  heading: "Delete Announcement",
  content: {
    type: "simple",
    props: {
      description: "Are you sure you want to delete this announcement?",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Yes",
};

export const createEventModal: ManageResidentModal = {
  heading: "Post Event",
  content: {
    type: "simple",
    props: {
      description: "Are you sure you want to save and post this event?",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Yes",
};

export const editEventModal: ManageResidentModal = {
  heading: "Edit Event",
  content: {
    type: "simple",
    props: {
      description: "Are you sure you want to edit and post this event?",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Yes",
};

export const delEventModal: ManageResidentModal = {
  heading: "Delete Event",
  content: {
    type: "simple",
    props: { description: "Are you sure you want to delete this event?" },
  },
  leftLabel: "Cancel",
  rightLabel: "Yes",
};

// manage Resident
export const mrViewAllModal: ManageResidentModal = {
  heading: "Admin Care Home",
  content: {
    type: "info",
    props: {
      data: [
        {
          name: "Shawn Kruz",
          email: "Shawnkruz@carehome.com",
        },
        {
          name: "Johnny Verna",
          email: "Johnnyverna@carehome.com",
        },
        {
          name: "Samantha Duncan",
          email: "Samanthaduncan@carehome.com",
        },
        {
          name: "Gavin Duncan",
          email: "Gavinduncan@carehome.com",
        },
        {
          name: "Shawn Kruz",
          email: "Shawnkruz@carehome.com",
        },
        {
          name: "Shawn Kruz",
          email: "Shawnkruz@carehome.com",
        },
      ],
    },
  },
  leftLabel: "",
  rightLabel: "",
  actionHiddenLeft: true,
  actionHiddentRight: true,
};

export const mrActivateResident: ManageResidentModal = {
  heading: "Activate Resident",
  description: "Do you want to activate ‘Tara Harpor’?",
  leftLabel: "Cancel",
  rightLabel: "Activate",
};

export const mrDeactivateResident: ManageResidentModal = {
  heading: "Deactivate Family Member",
  description: "Do you want to deactivate ‘Omar Smith’?",
  note: "Note: The data streaming will be stopped for this resident’s family members.",
  leftLabel: "Cancel",
  rightLabel: "Deactivate",
  rightColor: "error",
};

export const mrActivateFamilyMember: ManageResidentModal = {
  heading: "Activate Family Member",
  content: {
    type: "simple",
    props: {
      description: "Do you want to Activate ‘Omar Smith’?",
      note: "Note: The data streaming will be stopped for this resident’s family members.",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Activate",
};
export const mrDeactivateFamilyMember: ManageResidentModal = {
  heading: "Deactivate Family Member",
  content: {
    type: "simple",
    props: {
      description: "Do you want to deactivate ‘Omar Smith’?",
      note: "Note: The data streaming will be stopped for this resident’s family members.",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Deactivate",
  rightColor: "error",
};

export const mrInvitePrimaryContact: ManageResidentModal = {
  heading: "Invite Primary Contact",
  description: "Please enter primary contact email address.",
  leftLabel: "",
  rightLabel: "Send Invite",
};

export const mrInviteSent: ManageResidentModal = {
  heading: "Invite Sent",
  description: "An email invite has been sent.",
  leftLabel: "",
  rightLabel: "Okay",
};

// Manage Home

export const mhDeactivateFamilyMember: ManageResidentModal = {
  heading: "Deactivate Care Home",
  content: {
    type: "simple",
    props: {
      description: "Are you sure you want to deactivate ‘Asus Nursing Home’?",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Deactivate",
  rightColor: "error",
};

export const mhActivateFamilyMember: ManageResidentModal = {
  heading: "Activate Care Home",
  content: {
    type: "simple",
    props: {
      description: "Do you want to activate ‘Asus Nursing Home’?",
    },
  },
  leftLabel: "Cancel",
  rightLabel: "Activate",
};

export const mhConfirmCareHome: ManageResidentModal = {
  heading: "Confirm Care Home",
  content: {
    type: "simple",
    props: {
      description:
        "Is this the Care Home you were trying to add to your system?",
      name: "Maynard Nursing Home",
      address: "Address: 290 Yonge Street, North York, Ontario",
    },
  },
  leftLabel: "No",
  rightLabel: "Yes",
};

export const mhVerifyCareHome: ManageResidentModal = {
  heading: "Please verify the details of the Care Home:",
  content: {
    type: "secondaryInfo",
    props: {
      name: "Maynard Nursing Home",
      address: "Address: 290 Yonge Street, North York, Ontario",
      data: [
        {
          residents: "Shawn Kruz",
          dob: "Jan 13, 1956",
          primaryContact: "Ketty Smith",
        },
        {
          residents: "Johnny Verna",
          dob: "July 18, 1950",
          primaryContact: "Carol Jon",
        },
        {
          residents: "Samantha Duncan",
          dob: "May 21, 1959",
          primaryContact: "David Steede",
        },
        {
          residents: "Gavin Duncan",
          dob: "Jun 04, 1961",
          primaryContact: "Lauren Hollingsworth",
        },
        {
          residents: "Shawn Kruz",
          dob: "Jun 04, 1961",
          primaryContact: "Ketty Smith",
        },
        {
          residents: "Shawn Kruz",
          dob: "Jun 04, 1961",
          primaryContact: "Carol Jon",
        },
      ],
    },
  },
  leftLabel: "Don’t Add",
  rightLabel: "Confirm",
};

export const mhInviteSent: ManageResidentModal = {
  heading: "Invite Sent",
  content: {
    type: "simple",
    props: {
      description: "An email invite has been sent.",
    },
  },
  actionHiddenLeft: true,
  leftLabel: "No",
  rightLabel: "Okay",
};
