import { Styles } from "types/common";
import { InputBase as MuiInputbase } from "@mui/material";
import { styled } from "@mui/system";

const styles: Styles = {
  wrapper: {
    width: "100%",
    "& .label": {
      display: "inline",
      fontSize: "15px",
      width: "110px",
    },
  },

  labelPos: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& .label": {
      fontSize: "14px",
      minWidth: "110px",
    },
  },

  labelPosStyles: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "& .label": {
      minWidth: "0px",
      width: "0px",
    },
  },

  adornment: {
    position: "relative",
    top: "-10px",
  },

  label: {
    position: "static",
  },

  input: {
    flexGrow: 1,
    "& .MuiInputBase-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        MozAppearance: "none",
        "&::-moz-focus-inner": {
          borderStyle: "none",
        },
      },
    },
  },

  error: {
    marginLeft: "110px",
  },

  doneIcon: {
    color: "custom.misc.green",
  },
};

type InputBaseProps = {
  hasError: boolean;
  typed: boolean;
  valid: boolean;
};

export const InputBase = styled(MuiInputbase, {
  shouldForwardProp: (prop) =>
    prop !== "hasError" && prop !== "typed" && prop !== "valid",
})(({ hasError, typed, valid }: InputBaseProps) =>
  ({
    "&.MuiInputBase-root": {
      borderColor: valid
        ? "custom.misc.green"
        : hasError
          ? "red"
          : "custom.border.main",
    },
  }),
);

export default styles;
