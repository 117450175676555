import { Box } from "@mui/material";
import styles from "./styles";
import { Link } from "react-router-dom";

const HelpAndSupport = () => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.contactBox}>
        <Box className="contact-boxTitle">
          <Box className="contact">Contact Support</Box>
          <Box className="email">
            {"Email: "}
            <Box
              component="a"
              sx={styles.link}
              href="mailto: support@gerry.com"
            >
              support@gerry.com
            </Box>
          </Box>
          <Box sx={styles.phoneWrapper}>
            Call Us:
            <Box sx={styles.callLinks}>
              <Box component="a" sx={styles.link} href="tel:6479380383">
                6479380383
              </Box>
              {" or "}
              <Box component="a" sx={styles.link} href="tel:6472481555">
                6472481555
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.securityBox}>
        <Box
          component="a"
          href="https://mygerry.com/privacy.html"
          target="_blank"
          className="link"
        >
          Privacy Policy
        </Box>
      </Box>
      <Box sx={styles.securityBox}>
        <Box component="a" sx={styles.link}>
          <Link to="terms-and-conditions" target="_blank" className="link">
            Terms & Conditions
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpAndSupport;
