import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBase,
  InputLabel,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import MaskedInput from "./MaskedInput";

type PhoneProps<T> = UseControllerProps<T> &
  FormControlProps & {
    label: string;
    errors?: FieldErrors;
    customStyles?: SxProps;
    maskedFormat?: string;
  };

const Phone = <T extends FieldValues>({
  name,
  control,
  errors,
  label,
  rules,
  disabled = false,
  fullWidth = true,
  customStyles,
  maskedFormat = "000-000-0000",
  className,
  ...rest
}: PhoneProps<T>) => {
  const error = errors && name ? errors[name] : undefined;
  const showError = !!error?.message;

  return (
    <Controller
      render={({ field }) => (
        <FormControl
          className={className}
          fullWidth={fullWidth}
          sx={customStyles}
          variant="standard"
        >
          <InputLabel
            htmlFor={name}
            shrink
            className="label"
            required={!!rules?.required}
            disabled={disabled}
          >
            {label}
          </InputLabel>
          <InputBase
            value={field.value}
            onChange={field.onChange}
            disabled={disabled}
            onBlur={field.onBlur}
            error={showError}
            name={name}
            id={name}
            inputComponent={MaskedInput as any}
            inputProps={{ maskedFormat }}
          />

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};

export default Phone;
