import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN, userSessionActive } from "utils/common";

const refreshUrl = "/user/refresh-token";
const signInUrl = "/user/sign-in";
const validateTokenUrl = "user/validate-reset-token";
const signUpUrl = "invite/admins/";
const validateTempPass = "/user/validate-temporary-password";
const verifyAuthCode = "/user/verify-otp";
const staffUrl = "/user/sign-up";

const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_USER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let locationInfo: any;

let fetchLocation = async () => {
  try {
    const showPosition = (positionInstance) =>
      `${positionInstance.coords.latitude},${positionInstance.coords.longitude}`;

    const position = await new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    const latLng = showPosition(position);

    const response = await window.fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
      {
        method: "GET",
      },
    );

    const data = await response.json();

    locationInfo =
      data?.results?.[data?.results?.length - 2]?.address_components?.[0]
        ?.long_name;

    return locationInfo;
  } catch (error) {
    let message =
      "Please enable location access:\n" +
      "1. Click on the lock or info icon in the browser address bar.\n" +
      "2. Find the Location or Permissions section.\n" +
      '3. Select "Allow" or "Always allow" for this site.';
    window.alert(message);
    return Promise.reject();
  }
};

fetchLocation();

let failedUserQueue = [];

adminInstance.interceptors.request.use(handleRequest);
adminInstance.interceptors.response.use(handleResponse, (error) =>
  handleError(error, adminInstance, failedUserQueue),
);

function processQueue(error: AxiosError, token = null) {
  [failedUserQueue].forEach((failedQueue) => {
    failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });
    failedQueue.length = 0;
  });
}

async function handleRequest(req: AxiosRequestConfig) {
  req.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  if (req.url === signInUrl) {
    req.headers["x-role-type"] = "CARE_HOME_ADMIN";
  }

  if (!locationInfo) {
    try {
      await fetchLocation();
    } catch (error) {
      return error;
    }
  }

  req.headers["x-user-location"] = locationInfo;

  return req;
}

function handleResponse(response: AxiosResponse<any>) {
  if (
    response?.config &&
    [refreshUrl, signInUrl, staffUrl].includes(response.config.url)
  ) {
    /**
     * set token if user is verified
     */
    userSessionActive({
      accessToken: response.headers["x-access-token"],
      refreshToken: response.headers["x-refresh-token"],
    });
  }
  return response;
}

async function handleError(
  error: AxiosError<any>,
  instance: AxiosInstance,
  failedQueue: any[],
) {
  const status = error.response ? error.response.status : null;
  const originalRequest = error.config;
  if (
    status === 401 &&
    ![
      refreshUrl,
      validateTokenUrl,
      signInUrl,
      validateTempPass,
      verifyAuthCode,
    ].includes(error.config.url) &&
    !error.config.url.includes(signUpUrl)
  ) {
    /**
     * if access-token is expired, get new access-token from refresh-token and retry requests
     */

    if (isRefreshing) {
      /**
       * if refresh token api is pending, adding new request to failed queue
       */
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((accessToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return instance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    isRefreshing = true;
    return adminInstance
      .post(refreshUrl, {
        refreshToken: localStorage.getItem(REFRESH_TOKEN),
      })
      .then(async (res) => {
        const accessToken = res?.headers && res.headers["x-access-token"];
        const refreshToken = res?.headers && res.headers["x-refresh-token"];
        userSessionActive({ accessToken, refreshToken });

        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

        /**
         * processing all the failed request with new access token
         */
        return instance(originalRequest)
          .then((originalResponse) => {
            processQueue(null, accessToken);
            return originalResponse;
          })
          .catch((originalError) => {
            processQueue(originalError, null);
            return Promise.reject(originalError);
          });
      })
      .catch((err: AxiosError) => {
        if (err.config.url === refreshUrl) {
          localStorage.clear();
          window.location.reload();
        }
        return Promise.reject(err);
      })
      .finally(() => {
        isRefreshing = false;
      });
  }
  return Promise.reject(error);
}

export default adminInstance;
