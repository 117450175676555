import { Styles } from "types/common";

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "376px",
    margin: "132px auto",
    "& .MuiFormHelperText-root": {
      fontSize: "11px",
    },
  },

  resetPassword: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "40px",
  },

  currentPasswordInput: {
    marginBottom: "40px",
    "& .MuiInputBase-input ": {
      "&::-ms-reveal, &::-ms-clear": {
        display: "none",
      },
    },
  },

  input: {
    marginBottom: "18px",
    "& .MuiInputBase-input ": {
      "&::-ms-reveal, &::-ms-clear": {
        display: "none",
      },
    },
  },

  saveButton: {
    width: "120px",
    height: "40px",
    backgroundColor: "primary.dark",
  },

  editButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "62px",
    columnGap: "15px",
  },

  cancelButton: {
    width: "120px",
    height: "40px",
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "custom.background.darkGray",
    color: "custom.text.secondary",
    "&:hover": {
      backgroundColor: "white",
    },
  },
};

export default styles;
