import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBase,
  InputBaseProps,
  InputLabel,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  UseFormSetValue,
} from "react-hook-form";
import Calendar from "./Calendar";
import styles from "./styles";

type InputProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    errors?: FieldErrors;
    maxDate?: string;
    minDate?: string;
    view?: boolean;
    loading?: boolean;
    setValue: UseFormSetValue<T>;
    labelPos?: boolean;
    readOnly?: boolean;
    customStyles?: { [key: string]: SxProps };
    noSelected?: boolean;
  };

const Input = <T extends FieldValues>({
  name,
  control,
  label,
  type = "text",
  fullWidth = true,
  errors,
  rules,
  customStyles,
  maxDate,
  minDate,
  labelPos = false,
  inputProps,
  variant = "standard",
  className,
  setValue,
  view = false,
  readOnly = false,
  noSelected = false,
  ...rest
}: InputProps<T>) => {
  const error = errors[name];

  const setInputVal = (val: string) => {
    setValue(name, val as PathValue<T, Path<T> & string>, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <Controller
      render={({ field }) => (
        <FormControl
          sx={customStyles?.formControl}
          fullWidth={fullWidth}
          variant={variant}
          className={className}
        >
          <Box sx={{ ...styles.wrapper, ...customStyles?.wrapper } as SxProps}>
            <Box className={labelPos && "labelPos"}>
              <InputLabel shrink className="label" required={!!rules?.required}>
                {label}
              </InputLabel>
              <InputBase
                value={field.value}
                readOnly={true}
                disabled={readOnly}
                onChange={(e) => {
                  field.onChange(e);
                }}
                inputProps={{
                  ...inputProps,
                }}
                sx={{ ...styles.input, ...customStyles?.input } as SxProps}
                error={!!error}
                style={{
                  background: readOnly ? "none" : "white",
                }}
                endAdornment={
                  <Calendar
                    minDate={minDate}
                    maxDate={maxDate}
                    setDate={setInputVal}
                    readOnly={readOnly}
                    noSelected={noSelected}
                  />
                }
                {...rest}
              />
            </Box>
          </Box>
          {error && !view && (
            <FormHelperText sx={styles.error}>{error.message}</FormHelperText>
          )}
        </FormControl>
      )}
      name={name}
      control={control}
      rules={rules}
      {...rest}
    />
  );
};
export default Input;
