import { Box } from "@mui/material";
import { useState } from "react";
import ChangePassword from "./ChangePassword";
import Profile from "./Form";
import styles from "./styles";

const ProfileDefault = () => {
  const [changePassword, setChangePassword] = useState<boolean>(false);
  return (
    <Box sx={styles.wrapper}>
      {!changePassword ? (
        <Profile changePassword={() => setChangePassword(true)} />
      ) : (
        <ChangePassword showProfile={() => setChangePassword(false)} />
      )}
    </Box>
  );
};

export default ProfileDefault;
