import { createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "redux/thunks";
import { logoutAction } from "redux/slices/signOut";

interface UserDetails {
  id: string;
  isActive: true;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  phoneNumber: string;
  customRole?: string;
  address: {
    addressLine1: string;
    addresLine2: string;
    city: string;
    province: string;
    country: string;
    pincode: string;
  };
  email: string;
  careHomes: [
    {
      isActive: boolean;
      careHomeId: string;
      role: {
        id: string;
        name: string;
      };
    },
  ];
  residents: [{}];
}

interface IUser {
  isLoggedIn: boolean;
  userDetails: UserDetails;
  signUpEmail: string;
  role: string;
  signUpToken: string;
  newPassToken: string;
  newPassword: string;
  signInEmail: string;
  residentsRowData: { gerryId: string; status: boolean };
  globalInfoData: {
    heading: string;
    content: {
      label: string;
      value: string;
    }[];
    source: string;
  };
  mrModalView: {
    data: {
      name: string;
      email: string;
    }[];
  };
  careHomeTimeZone: string;
}

const initialState: IUser = {
  isLoggedIn: false,
  userDetails: {
    id: "",
    isActive: true,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    phoneNumber: "",
    gender: "",
    address: {
      addressLine1: "",
      addresLine2: "",
      city: "",
      province: "",
      country: "",
      pincode: "",
    },
    careHomes: [
      {
        careHomeId: "",
        isActive: true,
        role: {
          id: "",
          name: "",
        },
      },
    ],

    residents: [{}],
    email: "",
  },
  globalInfoData: {
    heading: "",
    content: [
      {
        label: "",
        value: "",
      },
    ],
    source: "",
  },
  mrModalView: {
    data: [
      {
        name: "",
        email: "",
      },
    ],
  },
  residentsRowData: { gerryId: "", status: false },
  signUpEmail: "",
  role: "",
  newPassToken: "",
  signUpToken: "",
  newPassword: "",
  signInEmail: "",
  careHomeTimeZone: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.isLoggedIn = true;
      state.userDetails = action.payload;
    },
    setUser: (state, action) => {
      state.userDetails = action.payload;
    },
    setSignUpEmail: (state, action) => {
      state.signUpEmail = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setNewPassToken: (state, action) => {
      state.newPassToken = action.payload;
    },
    setSignUpToken: (state, action) => {
      state.signUpToken = action.payload;
    },
    setNewPassword: (state, action) => {
      state.newPassword = action.payload;
    },
    setSignInEmail: (state, action) => {
      state.signInEmail = action.payload;
    },
    setResidentsRowData: (state, action) => {
      state.residentsRowData = action.payload;
    },
    setGlobalInfoData: (state, action) => {
      state.globalInfoData = action.payload;
    },
    setMrModalView: (state, action) => {
      state.mrModalView = action.payload;
    },
    setCareHomeTimeZone: (state, action) => {
      state.careHomeTimeZone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, (state, _action) => {
      state.isLoggedIn = false;
      state.userDetails = initialState.userDetails;
    });

    builder.addCase(logoutAction, (state, _action) => {
      state.isLoggedIn = false;
      state.userDetails = initialState.userDetails;
      state.residentsRowData = initialState.residentsRowData;
      state.newPassToken = initialState.newPassToken;
      state.role = initialState.role;
      state.signInEmail = initialState.signInEmail;
      state.signUpEmail = initialState.signUpEmail;
      state.globalInfoData = initialState.globalInfoData;
      state.mrModalView = initialState.mrModalView;
    });
  },
});

export const {
  loginUser,
  setSignUpEmail,
  setRole,
  setNewPassToken,
  setSignUpToken,
  setSignInEmail,
  setResidentsRowData,
  setNewPassword,
  setGlobalInfoData,
  setMrModalView,
  setUser,
  setCareHomeTimeZone,
} = userSlice.actions;

export default userSlice.reducer;
