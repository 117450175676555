import {
  InputLabel,
  FormControl,
  FormHelperText,
  FormControlProps,
  InputBaseProps,
  SxProps,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import styles from "./styles";
import errorMessage from "constants/errorMessage";

type FileUploadProps<T> = UseControllerProps<T> &
  FormControlProps &
  InputBaseProps & {
    label: string;
    labelStyle?: SxProps;
    labelPos?: boolean;
    maxLimit: number;
    multiple: boolean;
    disabled?: boolean;
    accept?: string;
    maxSize?: number;
    readOnly?: boolean;
    clearErrors: UseFormClearErrors<T>;
    setValue: UseFormSetValue<T>;
    getValues?: UseFormGetValues<T>;
    setError?: UseFormSetError<T>;
  };

const FileUpload = <T extends FieldValues>({
  control,
  name,
  rules,
  label,
  disabled = false,
  labelPos = true,
  maxLimit = 10,
  maxSize = 10000000, // 10 MB
  multiple = true,
  accept = "image/jpeg , application/pdf",
  readOnly = false,
  clearErrors,
  setValue,
  getValues,
  setError,
}: FileUploadProps<T>) => {
  const ref = useRef(null);
  const [files, setFiles] = useState<File[]>(getValues(name));
  const fileType = accept.split(",").map((str) => str.split("/")[1].trim());

  const validateFiles = (uploadFile: File) => {
    if (files.some((file) => uploadFile.name === file.name)) {
      setError(name, {
        type: "duplicateFile",
        message: errorMessage.fileUpload.alreadyExist,
      });
      return false;
    }

    if (uploadFile.size > maxSize) {
      setError(name, {
        type: "sizeLimit",
        message: errorMessage.fileUpload.maxFileSize,
      });
      return false;
    }

    if (!fileType.some((type) => uploadFile.name.includes(type))) {
      setError(name, {
        type: "invalidFiletype",
        message: errorMessage.fileUpload.invalidFileType,
      });
      return false;
    }

    clearErrors(name);
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let allFiles = { ...e.target.files };

    let rightFiles: File[] = [];
    for (let key in allFiles) {
      const err = validateFiles(allFiles[key]);
      if (err) {
        rightFiles.push(allFiles[key]);
      }
    }

    const sliceFiles = rightFiles.splice(0, maxLimit - files.length);

    if (sliceFiles.length)
      setFiles((prevFiles) => [...prevFiles, ...sliceFiles]);
  };

  const deleteFile = (fileName: string) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  useEffect(() => {
    setValue(name, files as PathValue<T, Path<T> & string>);
  }, [files, name, setValue]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ fieldState: { error } }) => (
        <Box sx={styles.wrapper}>
          <Box className={labelPos && "labelPos"}>
            <InputLabel
              shrink={false}
              className="label"
              disabled={disabled}
              required={!!rules?.required}
            >
              {label}
            </InputLabel>
            <FormControl fullWidth={true} sx={styles.inputWrapper}>
              <Box
                multiple={multiple}
                component="input"
                type="file"
                value=""
                onChange={handleChange}
                ref={ref}
                sx={styles.hide}
              />
              <Box sx={styles.filesWrapper}>
                {files.length < maxLimit && (
                  <Button
                    disabled={readOnly}
                    sx={styles.uploadBtn}
                    onClick={() => ref.current.click()}
                  >
                    Attach a file (max 2 files)
                  </Button>
                )}
                <Box sx={styles.files}>
                  {files.length !== 0 &&
                    files.map((file, idx) => (
                      <Box key={idx} sx={styles.file}>
                        {file.name.length > 10
                          ? `${file.name.slice(0, 12)}...`
                          : file.name}
                        {!readOnly && (
                          <Box
                            sx={styles.delIcon}
                            component="img"
                            src="/icons/del-file.svg"
                            alt="delete"
                            onClick={() => deleteFile(file.name)}
                          />
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>
              {!!error && (
                <FormHelperText sx={styles.error}>
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
      )}
    />
  );
};

export default FileUpload;
