import { FormState } from "react-hook-form";
import { Forms, ManageResidentData } from "types/common";
import { getTime, toLocalDate } from "./common";

export const getInfoData = ({
  data,
  careHomeTimeZone,
}: {
  data: ManageResidentData;
  careHomeTimeZone: string;
}) => {
  return {
    heading: data.residentName,
    content: [
      {
        label: "Resident  |",
        value: ` DOB:${dateInSpecifiedTimeZone(
          data?.dateOfBirth,
          "GMT",
        )} | Gender:${data.gender}`,
      },
      {
        label: "Gerry ID:",
        value: data.gerryId,
      },
      {
        label: "Last Action Date:",
        value: data?.lastAction
          ? `${toLocalDate(data?.lastAction)} | ${getTime(data?.lastAction)}`
          : "-",
      },
    ],
  };
};

export const dateInSpecifiedTimeZone = (date, targetTimeZone) => {
  const utcTime = new Date(date);

  const options = {
    timeZone: targetTimeZone,
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return utcTime.toLocaleString("en-US", options as any);
};

export const toggleButtonEnabled = (
  formState: FormState<Forms>,
  watch: any,
) => {
  return !(
    Object.keys(formState.errors).every(
      (field) => formState.errors[field] === "",
    ) &&
    Object.keys(formState.dirtyFields).length === Object.keys(watch()).length
  );
};

export const capitalize = (label: string) =>
  label?.charAt(0).toUpperCase() + label.slice(1);

export const primaryTransformer = (data) =>
  data?.map((member) => ({
    ...member,
    primaryContact: `${member?.firstName || ""} ${member?.lastName || ""}`,
    status: member?.isActive,
    invite: false,
    reqEmail: member?.email,
    email: member?.email ? member.email : "-",
  }));

export const familyTransformer = (data) =>
  data?.map((member) => {
    return {
      ...member,
      familyMember: `${member?.firstName || ""} ${member?.lastName || ""}`,
      status: member?.isActive,
      invite: false,
      reqEmail: member?.email,
      email: member?.email ? member.email : "-",
    };
  });

export const residentDataTransformer = (data) =>
  data?.map((item) => ({
    ...item,
    residentName: `${item?.firstName} ${item?.lastName}`,
    id: item?.id,
    status: item?.isActive,
    primaryContact: item.primaryMember?.[0]
      ? `${item.primaryMember?.[0]?.firstName || ""} ${
          item.primaryMember?.[0]?.lastName || ""
        }`
      : "-",
  }));

export const getTrimmedData = (obj) => {
  if (!obj || typeof obj !== "object") {
    return obj;
  }

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object") {
      getTrimmedData(obj[key]);
    } else if (typeof obj[key] === "string") {
      obj[key] = obj[key].trim();
    }
  });

  return obj;
};

export const preventNumberInput = (e: {
  keyCode: any;
  preventDefault: () => void;
}) => {
  const charCode = e.keyCode;
  if (
    charCode > 37 &&
    charCode > 39 &&
    charCode > 31 &&
    (charCode < 65 || charCode > 90) &&
    (charCode < 97 || charCode > 122)
  ) {
    e.preventDefault();
  }
};
