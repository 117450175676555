import dataProvider from "dataProvider";
import {
  ForgotPassword,
  ResetPasswordType,
  SignInData,
  SignOutData,
  SignUpData,
  StaffInfoData,
  ResidentStatusToggle,
  ResidentInvite,
  MemberStatusToggle,
  AllAppointments,
  MarkSlots,
  SlotsAnalytics,
  UnavailableSlots,
  UpdateSlots,
} from "types/api";

let fetcher = dataProvider("adminInstance");

const signIn = async (data: SignInData) => {
  return fetcher.post("/user/sign-in", data);
};

const forgotPassword = async (data: ForgotPassword) => {
  return fetcher.post("/user/forgot-password", {
    ...data,
    type: "careHomeAdmin",
  });
};

const resetPassword = async (data: ResetPasswordType) => {
  return fetcher.put("/user/reset-password", data);
};

const signOut = async (data: SignOutData) => {
  return fetcher.post("/user/sign-out", data);
};

const validateToken = async ({ token }: { token: string }) => {
  return fetcher.post(`user/validate-reset-token`, { token });
};

const signUpTokenValidation = async ({ token }: { token: string }) => {
  return fetcher.get(`invite/admins/${token}`);
};

const signUp = async (data: SignUpData) => {
  return fetcher.post("/user/validate-temporary-password", data);
};

const sendOtp = async (data: { email: string }) => {
  return fetcher.post("user/send-otp", data);
};

const verifyCode = async (data: { otp: string; token: string }) => {
  return fetcher.post("/user/verify-otp", data);
};

const staffInfo = async (data: StaffInfoData) => {
  return fetcher.post("/user/sign-up", data);
};

export const getAllResidents = async ({
  pccId,
  page,
  search,
}: {
  pccId: string;
  page: number;
  search: string;
}) => {
  return fetcher.get(
    `residents?careHomeId=${pccId}&perPage=10&page=${page}${
      !!search ? `&search=${search}` : ``
    }`,
  );
};

export const getCareHomeByPccLocal = async (pccId: string) => {
  return fetcher.get(`/care-home/${pccId}`);
};

export const getCareHomeInfo = async (pccId: string) => {
  return fetcher.get(`care-home/${pccId}/additional-info`);
};

export const updateResidentStatus = async ({
  pccId,
  id,
  isActive,
}: ResidentStatusToggle) => {
  return fetcher.put(`residents/${id}/status`, {
    isActive: !isActive,
  });
};

export const sendInviteResident = async (data: ResidentInvite) => {
  return fetcher.post(`/invite/member`, data);
};

export const getMember = async ({ pccId, gerryId, type }: any) => {
  return fetcher.get(
    `members?residentId=${gerryId}&type=${type === "primary"}`,
  );
};

export const updateMemberStatus = async ({
  pccId,
  gerryId,
  id,
  isActive,
}: MemberStatusToggle) => {
  return fetcher.put(`members/${id}/status`, {
    status: !isActive,
  });
};

export const updateProfile = async ({
  id,
  data,
}: {
  id: string;
  data: {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    customRole: string;
  };
}) => {
  return fetcher.put(`user/${id}`, {
    ...data,
  });
};

export const changePassword = async (data: { newPassword: string }) => {
  return fetcher.post("user/change-password", data);
};

export const getAllAppointments = async ({
  pccId,
  page,
  from,
  to,
  residentId,
  search,
}: AllAppointments) => {
  return fetcher.get(
    `/appointments?careHomeId=${pccId}&page=${page}&perPage=10${
      residentId ? `&residentId=${residentId}` : ``
    }${to ? `&to=${to}` : ``}${from ? `&from=${from}` : ``}${
      search ? `&search=${search}` : ``
    }`,
  );
};

export const deleteAppointmentById = async ({
  appointmentId,
  reason,
}: {
  appointmentId: string;
  reason: string;
}) => {
  return fetcher.delete(`appointments/${appointmentId}`, {}, { reason });
};

export const getSlotsAnalytics = async ({
  pccId,
  month,
  year,
}: SlotsAnalytics) => {
  return fetcher.get(
    `/appointments/unavailable-slots/analytics?careHomeId=${pccId}&month=${month}&year=${year}&timeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
  );
};

export const getUnavailableSlots = async ({
  pccId,
  from,
  to,
}: UnavailableSlots) => {
  return fetcher.get(
    `/appointments/unavailable-slots?careHomeId=${pccId}&to=${to}&from=${from}`,
  );
};

export const markSlot = async ({
  careHomeId,
  reason,
  startTime,
  endTime,
}: MarkSlots) => {
  return fetcher.post("/appointments/unavailable-slots", {
    careHomeId,
    reason,
    startTime,
    endTime,
  });
};

export const updateUnavailableSlot = async ({
  id,
  reason,
  startTime,
  endTime,
}: UpdateSlots) => {
  return fetcher.put(`/appointments/unavailable-slots/${id}`, {
    reason,
    startTime,
    endTime,
  });
};

export const markAvailable = async ({ id }: { id: string }) => {
  return fetcher.delete(`/appointments/unavailable-slots/${id}`, {});
};

export const markResidentNotNew = async (id: string) => {
  return fetcher.put(`residents/${id}/markAsNotNew`, {});
};

export const statusToggle = async (data: { id: string; status: boolean }) => {
  return fetcher.put(`care-home/${data.id}/status`, { isActive: !data.status });
};

export const fetchResidentMember = async (residentId: string) => {
  return fetcher.post(`/members/pcc?residentId=${residentId}`, {});
};

export {
  signIn,
  forgotPassword,
  resetPassword,
  signOut,
  validateToken,
  signUpTokenValidation,
  signUp,
  sendOtp,
  verifyCode,
  staffInfo,
};
