import { Box } from "@mui/material";
import { Input } from "components/common/FormComponents";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "./styles";
import { debounce } from "utils/debouncing";

type FormInputs = {
  search: string;
};

type SearchProps = {
  getText: (txt: string) => void;
};

const SearchBar = ({ getText }: SearchProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      search: "",
    },
  });

  const inputVal = watch("search");

  const searchText = useCallback(
    (val) => debounce(() => getText(val), 700),
    [getText],
  );

  useEffect(() => {
    searchText(inputVal);
  }, [inputVal, searchText]);

  const onSubmitForm = (formData: FormInputs) => formData;

  return (
    <Box sx={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Input
          control={control}
          label="Search"
          labelPos
          labelPosStyles
          name="search"
          showAdornment
          adornmentPos="start"
          className="search"
          errors={errors}
          rules={{}}
          placeholder="Search"
        />
      </form>
    </Box>
  );
};

export default SearchBar;
