import { Box } from "@mui/material";
import Button from "components/common/Button";
import { Password } from "components/common/FormComponents";
import PopUp from "components/common/PopUp";
import { SimplePopUp } from "components/common/PopUp/Modal";
import errorMessage from "constants/errorMessage";
import { route } from "constants/routes";
import useBtnDisableToggler from "hooks/useBtnDisableToggler";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { setNewPassToken, setNewPassword } from "redux/slices/user";
import { useAppDispatch, useAppSelector } from "redux/store";
import { sendOtp } from "utils/api/user";
import { getTrimmedData } from "utils/helper";
import regex from "utils/regex";
import styles from "./styles";

export type ResetPasswordFormData = {
  newPassword: string;
  confirmPassword: string;
};

const NewPassword = () => {
  const { signUpEmail } = useAppSelector((state) => state.user);
  const {
    control,
    formState,
    setError,
    getValues,
    handleSubmit,
    clearErrors,
    watch,
  } = useForm<ResetPasswordFormData>({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    criteriaMode: "all",
    mode: "onTouched",
  });
  const history = useHistory();

  const [isDisabled] = useBtnDisableToggler({ formState, watch });
  const [openModal, setOpenModal] = useState(false);
  const { password: passwordRegex } = regex;
  const dispatch = useAppDispatch();

  const { mutate: otpSend } = useMutation(
    "sendOtp",
    (data: { email: string }) => sendOtp(getTrimmedData(data)),
    {
      onSuccess: (data) => {
        dispatch(setNewPassToken(data.data.data.token));
        dispatch(setNewPassword(getValues("newPassword")));
        setOpenModal(true);
      },
    },
  );

  const onSubmit = handleSubmit((data) => {
    otpSend({ email: signUpEmail });
  });

  const passwordError = formState.errors.confirmPassword;

  useEffect(() => {
    if (passwordError) setError("newPassword", {});
    else clearErrors("newPassword");
  }, [passwordError, setError, clearErrors]);

  const handleClose = () => {
    history.replace(route.authentication.path);
    setOpenModal(false);
  };

  return (
    <>
      <PopUp
        open={openModal}
        handleClose={handleClose}
        heading="Authentication Code"
        actionLeft={{
          hidden: true,
        }}
        actionRight={{
          label: "Okay",
        }}
        handleRightBtnClick={handleClose}
      >
        <SimplePopUp description="An authentication code has been sent, please check your email." />
      </PopUp>
      <Box sx={styles.wrapper}>
        <Box sx={styles.resetPassword}>New Password</Box>
        <Box sx={styles.heading}>Please choose a new password.</Box>
        <Box component="form" onSubmit={onSubmit}>
          <Password
            name="newPassword"
            label="Create New Password"
            errors={formState.errors}
            control={control}
            customStyles={styles.input}
            rules={{
              required: errorMessage.required,
              validate: {
                isValid: (value) =>
                  value &&
                  passwordRegex.isUpper.test(value.trim().toString()) &&
                  value.length >= 8 &&
                  passwordRegex.isLower.test(value.trim().toString()) &&
                  passwordRegex.isNum.test(value.trim().toString()) &&
                  passwordRegex.isSpecial.test(value.trim().toString()),
              },
            }}
          />

          <Password
            name="confirmPassword"
            label="Confirm New Password"
            errors={formState.errors}
            control={control}
            customStyles={styles.input}
            showHelperText={true}
            getValues={getValues}
            rules={{
              required: errorMessage.required,
              validate: {
                isSame: (value) =>
                  (value.trim() === getValues("newPassword").trim() &&
                    !!value) ||
                  errorMessage.invalidPassword,
                isValid: (value) =>
                  (value &&
                    passwordRegex.isUpper.test(value.trim().toString()) &&
                    value.length >= 8 &&
                    passwordRegex.isLower.test(value.trim().toString()) &&
                    passwordRegex.isNum.test(value.trim().toString()) &&
                    passwordRegex.isSpecial.test(value.trim().toString())) ||
                  errorMessage.password,
              },
            }}
          />

          <Button
            type="submit"
            label="Continue"
            customStyles={styles.continueBtn}
            disabled={isDisabled}
          />
        </Box>
      </Box>
    </>
  );
};

export default NewPassword;
