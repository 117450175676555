import { Box, SxProps } from "@mui/material";
import Button from "components/common/Button";
import PopUp, { Action } from "components/common/PopUp";
import Toggle from "components/common/Toggle";
import { useState } from "react";
import { InfoPopUp } from "../PopUp/Modal";
import { useAppSelector } from "redux/store";
import styles from "./styles";

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type InfoModal = {
  data: {
    name: string;
    email: string;
  }[];
} & ModalData;

type InfoProps = {
  content: {
    label: string;
    value: string;
    action?: boolean;
  }[];
  heading: string;
  state: boolean;
  updateState: (val: boolean) => void;
  handleOnClick?: () => void;
  mrViewAllModal?: InfoModal;
  customStyles?: {
    [key: string]: SxProps;
  };
  disabled?: boolean;
  fromPcc?: boolean;
  showToggle?: boolean;
};

const Info = ({
  content,
  heading,
  state,
  updateState,
  mrViewAllModal,
  disabled,
  fromPcc = true,
  showToggle = true,
  ...rest
}: InfoProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<InfoModal>(mrViewAllModal);

  const {
    user: { globalInfoData },
  } = useAppSelector((stateInstance) => stateInstance);

  const handleModal = () => {
    setOpenModal(true);
    setModalData(mrViewAllModal);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const source = globalInfoData?.source;

  return (
    <>
      {!!modalData && (
        <PopUp
          open={openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={modalData.actionRight}
          handleRightBtnClick={handleClose}
        >
          <InfoPopUp data={modalData.data} />
        </PopUp>
      )}
      <Box sx={styles.wrapper}>
        <Box sx={styles.header}>
          <Box>{heading}</Box>
          {showToggle && (
            <Box sx={styles.toggleWrapper}>
              <Toggle
                onChange={(val) => updateState(val)}
                value={state}
                disabled={disabled}
                {...rest}
              />
              {source === "pcc" && "(PointClickCare)"}
            </Box>
          )}
        </Box>

        {content?.map((item, idx) => (
          <Box sx={styles.sub} key={idx}>
            <Box>{item.label}</Box>
            <Box>
              {item.action ? (
                <Button
                  label={item?.value}
                  sx={styles.view}
                  onClick={handleModal}
                />
              ) : (
                <Box>{item?.value}</Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Info;
