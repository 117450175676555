import { Styles } from "types/common";
import { styled } from "@mui/system";

const styles: Styles = {
  btn: {
    fontSize: "12px",
  },
};

type ToggleBtnProps = {
  active: boolean;
};

export const ToggleBtn = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }: ToggleBtnProps) =>
  ({
    height: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "125px",
    borderRadius: "2px",
    fontWeight: "500",
    backgroundColor: active ? "success.light" : "error.light",

    "& .MuiToggleButtonGroup-grouped": {
      fontSize: "12px",
      height: "22px",
      width: "60px",
      borderWidth: "0",
      borderColor: "none",
      borderRadius: "2px",
      backgroundColor: active ? "white" : "error.light",
      "& .Mui-selected": {
        color: "custom.text.main",
        "&:hover": {
          backgroundColor: "none",
        },
      },
      "&:hover": {
        backgroundColor: active ? "white" : "error.light",
      },
    },

    "& .Mui-selected": {
      color: "black !important",
      "&:hover": {
        backgroundColor: "none",
      },
    },

    "& .activeBtn": {
      color: active ? "custom.text.main" : "custom.text.light",
      backgroundColor: active ? "success.light" : "white",
      "&:hover": {
        backgroundColor: active ? "success.light" : "white",
      },
    },

    "& .inactiveBtn": {
      color: active ? "custom.text.light" : "custom.text.main",
      backgroundColor: active ? "white" : "transparent",
      "&:hover": {
        backgroundColor: active ? "white" : "error.light",
      },
    },

    "& .MuiButtonBase-root ": {
      borderRadius: "2px",
      padding: "0 10px",
      textTransform: "capitalize",
      backgroundColor: "none",
      "& .hover": {
        backgroundColor: "none",
      },
    },
  }),
);

export default styles;
