import { Box, SxProps } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DataGrid from "components/common/DataGrid";
import PopUp, { Action } from "components/common/PopUp";
import { SimpleInput, SimplePopUp } from "components/common/PopUp/Modal";
import Toggle from "components/common/Toggle";
import { errorModal } from "constants/dummyData";
import errorMessage from "constants/errorMessage";
import useBtnDisableToggler from "hooks/useBtnDisableToggler";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useAppSelector } from "redux/store";
import { MemberStatusToggle } from "types/api";
import { ManageResidentData } from "types/common";
import { getMember, updateMemberStatus } from "utils/api/user";
import { familyTransformer, primaryTransformer } from "utils/helper";
import regex from "utils/regex";
import InviteResident from "../InviteResident";
import styles from "../styles";

type ModalData = {
  heading: string;
  actionLeft: Action;
  actionRight: Action;
};

type Modal = "activate" | "change" | "invite" | "unableToFetch" | undefined;

type ContactsProps = {
  contact: ManageResidentData;
  refetchAllResidents: any;
  residentId: string;
};

type InviteSent = {
  props: {
    description: string;
  };
} & ModalData;

type ToggleResident = {
  props: {
    description: string;
    note?: string;
  };
} & ModalData;

export type ChangePrimaryContactFormData = {
  newPrimaryContact: string;
};

const Contacts = ({
  contact,
  refetchAllResidents,
  residentId,
}: ContactsProps) => {
  const [rowData, setRowData] = useState<any>();
  const {
    user: {
      userDetails: { careHomes },
    },
  } = useAppSelector((state) => state);

  const pccId = careHomes?.[0].careHomeId;

  const { data: primaryMembers, refetch: refetchPrimary } = useQuery(
    ["getPrimary", contact?.id],
    () => getMember({ pccId, gerryId: contact?.id, type: "primary" }),
    {
      onSuccess: () => {
        refetchAllResidents();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { data: familyMembers, refetch: refetchFamily } = useQuery(
    ["getFamily", contact?.id],
    () => getMember({ pccId, gerryId: contact?.id, type: "family" }),
    {
      onSuccess: () => {
        refetchAllResidents();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { mutate, isLoading } = useMutation(
    "statusToggle",
    (data: MemberStatusToggle) => updateMemberStatus(data),
    {
      onSuccess: () => {
        refetchAllResidents();
        refetchPrimary();
        refetchFamily();
        handleClose();
      },
      onError: (err) => {
        if (err["response"].data.statusCode !== 401) {
          setModalData(errorModal);
          setOpenModal("unableToFetch");
        }
      },
      retry: 1,
    },
  );

  const { control, formState, watch, reset } =
    useForm<ChangePrimaryContactFormData>({
      defaultValues: {
        newPrimaryContact: "",
      },
      mode: "onTouched",
    });
  const [isDisabled] = useBtnDisableToggler({
    formState,
    watch,
  });

  const [openModal, setOpenModal] = useState<Modal>(undefined);
  const [pageSize, setPageSize] = useState<number>(5);

  const getDescWithName = (name, isActive) => {
    return isActive
      ? `Do you want to deactivate '${name}'?`
      : `Do you want to activate '${name}'?`;
  };

  const deactivateResident: ToggleResident = {
    heading: "Deactivate Family Member",
    props: {
      description: "Are you sure you want to deactivate ‘Asus Nursing Home’?",
      note: "Note: The data streaming will be stopped for this resident’s family members.",
    },

    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      label: "Deactivate",
      disabled: false,
      color: "error",
    },
  };

  const activateResident: ToggleResident = {
    heading: "Activate Family Member",
    props: {
      description: "Do you want to activate ‘Asus Nursing Home’?",
      note: "",
    },
    actionLeft: {
      label: "Cancel",
    },
    actionRight: {
      disabled: false,
      label: "Activate",
    },
  };

  const inviteSent: InviteSent = {
    heading: "Invite Sent",
    props: {
      description: "An email invite has been sent.",
    },
    actionLeft: {
      hidden: true,
    },
    actionRight: {
      label: "Okay",
      disabled: false,
      onClick: () => handleClose(),
    },
  };

  const renderStatus = (params: GridCellParams, type = "primary") => {
    return (
      <>
        {type === "primary" ? (
          <Box sx={styles.actionIcon}>
            <Toggle
              value={params.row.status}
              onChange={() => handleToggle(params.row, type)}
            />
          </Box>
        ) : (
          <Box sx={params?.row?.status ? styles.active : styles.inActive}>
            {params?.row?.status ? "On" : "Off"}
          </Box>
        )}
      </>
    );
  };

  const renderInvitePrimary = (params: GridCellParams) => {
    return (
      <Box sx={styles.tableLink}>
        <InviteResident
          params={{ ...params.row, id: residentId }}
          label="Enter the Email Address of the new Primary Contact of this resident."
          type="primaryMember"
          refetchPrimary={refetchPrimary}
        />
      </Box>
    );
  };

  const renderInvite = (params: GridCellParams) => {
    return (
      <Box sx={params.row.status ? styles.tableLink : styles.tableLinkDisabled}>
        <InviteResident
          params={{ ...params.row, id: residentId }}
          type="familyMember"
          secondaryHeading="Invite Family Member"
          refetchPrimary={refetchPrimary}
          refetchFamily={refetchFamily}
        />
      </Box>
    );
  };

  const primaryContactColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "primaryContact",
      headerName: "Primary Contact",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email ID",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Data",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderStatus,
    },

    {
      field: "invite",
      headerName: "Invite",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: renderInvitePrimary,
    },
  ];

  const familyMemberColumn: GridColDef[] = [
    {
      field: "id",
      headerName: "S.No",
      sortable: false,
      flex: 1,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "familyMember",
      headerName: "Family Member",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email ID",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Data",
      sortable: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => renderStatus(params, "family"),
    },
    {
      field: "invite",
      headerName: "",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => renderInvite(params),
    },
  ];

  const handleToggle = (data, type = "primary") => {
    const name = type === "primary" ? data.primaryContact : data.familyMember;
    setRowData(data);
    data.status
      ? setModalData({
          ...deactivateResident,
          props: {
            ...deactivateResident.props,
            description: getDescWithName(name, data.status),
          },
        })
      : setModalData({
          ...activateResident,
          props: {
            ...activateResident.props,
            description: getDescWithName(name, data.status),
          },
        });
    setOpenModal("activate");
  };

  const [modalData, setModalData] = useState(deactivateResident);

  const handleClose = () => {
    setOpenModal(undefined);
    reset();
  };

  const handleModal = () => {
    switch (openModal) {
      case "activate": {
        setOpenModal(undefined);
        mutate({ gerryId: contact?.gerryId, pccId, ...rowData });
        reset();
        break;
      }
      case "change": {
        setOpenModal("activate");
        setModalData(inviteSent);
        mutate({ gerryId: contact?.gerryId, pccId, ...rowData });
        break;
      }
      case "invite": {
        setOpenModal(undefined);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <Box
      sx={{ ...styles.wrapper, ...styles.familyMemberTableWrapper } as SxProps}
    >
      {!!modalData && (
        <PopUp
          open={!!openModal}
          handleClose={handleClose}
          heading={modalData.heading}
          actionLeft={modalData.actionLeft}
          actionRight={{
            ...modalData.actionRight,
            disabled: openModal === "change" ? isDisabled : false,
          }}
          handleRightBtnClick={handleModal}
          loading={isLoading}
        >
          {openModal === "activate" && (
            <SimplePopUp
              description={modalData.props.description}
              note={modalData.props.note}
            />
          )}

          {openModal === "change" && (
            <SimpleInput
              name="newPrimaryContact"
              label="Enter the Email Address of the new Primary Contact of this resident."
              control={control}
              placeholder="example@gmail.com"
              errors={formState.errors}
              autoFocus={false}
              rules={{
                required: errorMessage.required,
                pattern: {
                  value: regex.email,
                  message: errorMessage.invalidEmail,
                },
              }}
            />
          )}

          {openModal === "invite" && (
            <SimplePopUp description={modalData.props.description} />
          )}

          {openModal === "unableToFetch" && (
            <SimplePopUp
              description={modalData.props.description}
              note={modalData.props.note}
            />
          )}
        </PopUp>
      )}

      <DataGrid
        rows={primaryTransformer(primaryMembers?.data?.data)?.slice(-1) || []}
        columns={primaryContactColumn}
        disableSelectionOnClick
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={primaryMembers?.data?.metadata?.total || 0}
        loading={false}
        customStyles={{ height: 150 }}
        hideFooterPagination
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No primary member added yet."
      />

      <DataGrid
        rows={familyTransformer(familyMembers?.data?.data) || []}
        columns={familyMemberColumn}
        disableSelectionOnClick
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pageSize={pageSize}
        rowCount={familyMembers?.data?.metadata?.total || 0}
        loading={false}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        emptyTable="No family member added yet."
      />
    </Box>
  );
};

export default Contacts;
